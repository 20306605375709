import React from 'react';
import './SmallButton.css';

function SmallButton({label, onClick, green=true}) {
    return (
        <button className={green? 'smallbutton-container-green' : 'smallbutton-container-grey'} onClick={onClick} type='button'>
            <div className={green? 'smallbutton-label-green' : 'smallbutton-label-grey'}>
                {label}
            </div>
        </button>
    );
}

export default SmallButton;
