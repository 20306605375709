import React from 'react';
import GoogleMapReact from 'google-map-react';
import PriceMarker from "../PriceMarker/PriceMarker";
import ListingTypeMarker from "../ListingTypeMarker/ListingTypeMarker";

const LATITUDE = 4.1156735;
const LONGITUDE = -72.9301367;

export const markerTypes = {
    LISTING_TYPE_MARKER: 'LISTING_TYPE_MARKER',
    PRICE_MARKER: 'PRICE_MARKER',
};

class SimpleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: LATITUDE,
            lng: LONGITUDE
        },
        zoom: 10,
        markers: []
    };

    constructor(props) {
        super(props);

        this.state = {
            map: null,
            maps: null,
        }
    }

    createdPolylines = null;
    createdPolygons = [];

    renderPolylines(polylines) {
        if (polylines && polylines.coordinates.length > 0) {
            this.resetDrawing();

            const path = new this.state.maps.Polyline({
                path: polylines.coordinates,
                holes: polylines.holes,
                geodesic: true,
                strokeColor: "#88D203",
                fillColor: "rgba(136,210,3,0.5)",
                strokeWidth: 1
            });

            path.setMap(this.state.map);

            this.createdPolylines = path;
            this.createdPolygons = [];
        }
    }

    renderPolygons(polygons) {
        if ((!polygons || polygons.length === 0) && !this.props.polylines) {
            this.resetDrawing();
        }

        if (polygons && polygons.length > 0) {
            this.resetDrawing();

            this.createdPolygons = [];

            for (let p of polygons) {
                const poly = new this.state.maps.Polygon({
                    path: p.coordinates,
                    holes: p.holes,
                    geodesic: true,
                    strokeColor: "#88D203",
                    fillColor: "rgba(136,210,3,0.5)",
                    strokeWidth: 1
                });
                poly.setMap(this.state.map);

                this.createdPolygons.push(poly)
            }

            this.createdPolylines = null;
        }
    }

    resetDrawing() {
        if (this.createdPolylines) this.createdPolylines.setMap(null);
        if (this.createdPolygons) this.createdPolygons.forEach(p => p.setMap(null));
    }

    render() {

        return (
            <GoogleMapReact
                {...this.props}
                bootstrapURLKeys={{ key: 'AIzaSyAYNGTQVlz05OTWG8l5TJA6zCnlEFQUeEA' }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
                onGoogleApiLoaded={({ map, maps }) => {
                    this.setState({map, maps});
                    if (this.props.handleApiLoaded) this.props.handleApiLoaded(map, maps);
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                id={'map'}
                key={this.props.key}
                ref={this.props.innerRef}
                center={this.props.center? this.props.center : { lat: LATITUDE, lng: LONGITUDE }}
                zoom={this.props.zoom? this.props.zoom : 10}
                onDragEnd={this.props.onDragEnd}
                onCenterChanged={() => this.props.onCenterChanged(true)}
                onZoomChanged={() => this.props.onCenterChanged(true)}
                options={{ scrollwheel: true}}
                onClick={this.props.onClick}
                onDrag={this.props.onDrag}
                draggable={this.props.draggable}
            >
                {
                    this.props.markers?.map(
                        (m, i) =>
                            this.props.markerType === markerTypes.PRICE_MARKER ?
                                <PriceMarker
                                    key={i}
                                    lat={m.latitude}
                                    lng={m.longitude }
                                    property={m}
                                    amount={m.price? m.price : 0}
                                    listingType={m.listingType}
                                    onClick={() => this.props.onMarkerClick(m)}
                                />
                                :
                                <ListingTypeMarker
                                    key={i}
                                    lat={m.latitude}
                                    lng={m.longitude }
                                    type={m.listingType}
                                    property={m}
                                    onClick={() => this.props.onMarkerClick(m)}
                                />
                    )
                }
                {
                    (this.props.polylines || this.props.polylines === [])  && this.renderPolylines(this.props.polylines)
                }
                {
                    (this.props.polygons || this.props.polygons === []) && this.renderPolygons(this.props.polygons)
                }
                </GoogleMapReact>
        );
    }
}

export default React.forwardRef((props, ref) => <SimpleMap
    innerRef={ref} {...props}
/>);
