export const propertyActionTypes = {
    FETCH_PROPERTIES: 'PROPERTY/FETCH_PROPERTIES',

    FETCH_PROPERTY_BY_ID_REQUEST: 'PROPERTY/FETCH_PROPERTY_BY_ID_REQUEST',
    FETCH_PROPERTY_BY_ID_SUCCESS: 'PROPERTY/FETCH_PROPERTY_BY_ID_SUCCESS',
    FETCH_PROPERTY_BY_ID_FAILURE: 'PROPERTY/FETCH_PROPERTY_BY_ID_FAILURE',

    FETCH_PROPERTIES_INSIDE_AREA_REQUEST: 'PROPERTY/FETCH_PROPERTIES_INSIDE_AREA_REQUEST',
    FETCH_PROPERTIES_INSIDE_AREA_SUCCESS: 'PROPERTY/FETCH_PROPERTIES_INSIDE_AREA_SUCCESS',
    FETCH_PROPERTIES_INSIDE_AREA_FAILURE: 'PROPERTY/FETCH_PROPERTIES_INSIDE_AREA_FAILURE',

    CREATE_PROPERTY_REQUEST: 'PROPERTY/CREATE_PROPERTY_REQUEST',
    CREATE_PROPERTY_SUCCESS: 'PROPERTY/CREATE_PROPERTY_SUCCESS',
    CREATE_PROPERTY_FAILURE: 'PROPERTY/CREATE_PROPERTY_FAILURE',

    UPDATE_PROPERTY_REQUEST: 'PROPERTY/UPDATE_PROPERTY_REQUEST',
    UPDATE_PROPERTY_SUCCESS: 'PROPERTY/UPDATE_PROPERTY_SUCCESS',
    UPDATE_PROPERTY_FAILURE: 'PROPERTY/UPDATE_PROPERTY_FAILURE',

    DELETE_PROPERTY_REQUEST: 'PROPERTY/DELETE_PROPERTY_REQUEST',
    DELETE_PROPERTY_SUCCESS: 'PROPERTY/DELETE_PROPERTY_SUCCESS',
    DELETE_PROPERTY_FAILURE: 'PROPERTY/DELETE_PROPERTY_FAILURE',

    FETCH_PROPERTIES_FROM_AGENT_REQUEST: 'PROPERTY/FETCH_PROPERTIES_FROM_AGENT_REQUEST',
    FETCH_PROPERTIES_FROM_AGENT_SUCCESS: 'PROPERTY/FETCH_PROPERTIES_FROM_AGENT_SUCCESS',
    FETCH_PROPERTIES_FROM_AGENT_FAILURE: 'PROPERTY/FETCH_PROPERTIES_FROM_AGENT_FAILURE',

    CHANGE_FILTERS: 'PROPERTY/CHANGE_FILTERS',
};
