import React from 'react';
import './PrimaryButton.css';

function PrimaryButton({label, onClick, disabled}) {
    return (
        <button className='primarybutton-container' onClick={onClick} type='button' disabled={disabled}>
            <div className='primarybutton-label'>
                {label}
            </div>
        </button>
    );
}

export default PrimaryButton;
