import React, {useEffect, useState} from 'react';
import './Support.css';
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/modules";
import SendSupportMessage from "../../components/SendSupportMessage/SendSupportMessage";

function Support() {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.user.fetchUser());
    }, []);

    return (
        <div className="support-container">

            <div className="support-header">
                <img src='support_screen_header.png'  className="support-header-image" alt='header'/>
            </div>

            <div className="support-message-col">
                <SendSupportMessage user={user}/>
            </div>
        </div>
    );
}

export default Support;
