import React, {useEffect} from 'react';
import './SearchAgent.css';
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/modules";
import { useHistory } from "react-router-dom";
import AgentSearchResult from "../../components/AgentSearchResult/AgentSearchResult";
import FadeLoader from "react-spinners/FadeLoader";

function SearchAgent() {
    const agents = useSelector(state => state.agent.agents);
    const fetchingAgents = useSelector(state => state.agent.fetchingAgents);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(actions.agent.fetchAgents());
    }, []);

    function renderList() {
        return agents.map(c =>
            <div key={c._id} onClick={() => history.push("/agent/" + c._id)}>
                <div className='searchagent-separator'/>
                <AgentSearchResult user={c} lastReview={c?.ratings[c.ratings.length-1]}/>
            </div>
        );
    }

    return (
        <div className="searchagent-container">

            <div className="searchagent-header">
            </div>

            <div className="searchagent-results">
                <div className="searchagent-results-title">Resultados encontrados</div>
                <FadeLoader
                        color={'#88D203'}
                        loading={fetchingAgents}
                    /> 
                {renderList()}
            </div>
        </div>
    );
}

export default SearchAgent;
