import React, {useState, useEffect} from 'react';
import './Login.css';
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import {Link} from "react-router-dom";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";
import Input from "../../components/Input/Input";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store";
import { useHistory, useLocation } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";

function Login(){
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loginLoading);
    const loggedIn = useSelector(state => state.user.loggedIn);
    const loginFailed = useSelector(state => state.user.loginFailed);
    const [email, setEmail] = useState( null);
    const [password, setPassword] = useState( null);
    const history = useHistory();
    const { state } = useLocation();
    const redirect = state?.from || "/";

    function submit() {
        dispatch(actions.user.login(email, password));
    }

    useEffect(function () {
        if (loggedIn) {
            history.push(redirect);
        }
    }, [loading, loggedIn]);

    return (
        <form className='login-container'>

            <Link to="/">
                <img src={'logo-wo-bg.png'} className="login-logo" alt="logo" />
            </Link>

            <div className='login-form-content'>
                <div className='login-title'>
                    ¡Bienvenido!
                </div>
                <div className='login-subtitle'>
                    Ingrese su correo electrónico
                </div>

                <Input placeholder={'example@example.com'} type={'email'} label='E-mail' onChange={setEmail} onSubmit={submit}/>
                <Input placeholder={'● ● ● ● ● ●'} type={'password'} label='contraseña' onChange={setPassword} onSubmit={submit} />

                <FadeLoader
                    color={'#88D203'}
                    loading={loading}
                />
            </div>

            {
                loginFailed &&
                <div className='login-error'>
                    El correo electrónico o la contraseña son incorrectos
                </div>
            }
        
            <Link to="/recover-password">
                <div className='login-tip'>
                    ¿Olvidaste tu contraseña?
                </div>
            </Link>

            <PrimaryButton label='Iniciar Sesión' onClick={submit}/>
            <p>o</p>
            <Link to='/register'>
                <SecondaryButton label='Crea una cuenta'/>
            </Link>

        </form>
    );
}

export default Login;
