import React from 'react';
import './UserContact.css';
import ReactStars from 'react-stars'
import {FaPhoneAlt} from "react-icons/all";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function UserContact({user}) {
    let [phoneCopiedToClipboard, setPhoneCopiedToClipboard] = React.useState(false);
    let imageUrl = user?.picture ? user.picture : './default-user-2.png';

    return (
        <div className='usercontact-container'>

            <img src={imageUrl} className='usercontact-img' alt={'user'}/>

            <div className='usercontact-content'>

                <div className='usercontact-title'>
                    {user?.username || 'Username'}
                </div>


                <div className='usercontact-ratingsrow'>
                    {
                        user?.ratingAverage &&
                        <ReactStars
                            count={5}
                            value={user?.ratingAverage}
                            size={24}
                            edit={false}
                            color2={'#FAB31E'} />
                    }
                    {
                        user?.ratingAverage &&
                        <div className='usercontact-ratingstext'>
                            ({user?.ratings.length || '0'})
                        </div>
                    }
                </div>


                {
                    !user?.hidePhone && (
                        <div className='usercontact-phonerow'>
                            <FaPhoneAlt/>
                            <CopyToClipboard 
                                text={user?.phone} 
                                onCopy={() => {setPhoneCopiedToClipboard(true); setTimeout(() => setPhoneCopiedToClipboard(false), 3000);}}>
                                <span className='usercontact-phonetext'>
                                    {user?.phone}
                                </span>
                            </CopyToClipboard>
                            {
                                phoneCopiedToClipboard &&
                                <div>
                                    Copiado al portapapeles!
                                </div>
                            }
                        </div>
                    )
                }   
                
            </div>
        </div>
    );
}

export default UserContact;
