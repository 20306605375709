import React from 'react';
import './Navbar.css';
import {NavLink, Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {actions} from '../../store';
import { GrMenu, GrDown } from 'react-icons/gr';
import { withRouter } from 'react-router-dom';

function Navbar({ history }) {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.user.loggedIn);

    function toggleMenu() {
        var x = document.getElementById("collapsible-menu-options");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }
    
    history.listen(() => {
        var x = document.getElementById("collapsible-menu-options");
        if (x) {
            if (x.style.display === "block") {
                x.style.display = "none";
            }
        }
    });

    return (
        <div className="navbar-container">
            <div className="navbar-section">
                <NavLink className="navbar-item" to={{ pathname: '/sell' }}>
                    Vender
                </NavLink>
                <NavLink className="navbar-item" to={{ pathname: '/search', search: "?listingType=sale" }}>
                    Comprar
                </NavLink>
                <NavLink className="navbar-item" to={{ pathname: '/search', search: "?listingType=rent" }}>
                    Alquilar
                </NavLink>
                <NavLink className="navbar-item" to='/search-agent'>
                    Buscador de Agentes
                </NavLink>
            </div>

            <NavLink to="/">
                <img src={'/logo-wo-bg.png'} className="navbar-logo" alt="logo" />
            </NavLink>

            <div className='navbar-section' style={{ justifyContent: 'flex-end' }}>
                {
                    loggedIn &&
                    <NavLink className="navbar-item" to='/contacts'>
                        Mensajes
                    </NavLink>
                }
                <div className="dropdown">
                    <button className="dropbtn">Perfil <GrDown/></button>
                    <div className="dropdown-content">
                        <NavLink className="navbar-item" to='/help'>
                            Ayuda
                        </NavLink>
                        {
                            !loggedIn &&
                            <>
                                <NavLink className="navbar-item" to='/register'>
                                    Registrarse
                                </NavLink>
                                <NavLink className="navbar-item" to='/login'>
                                    Iniciar Sesión
                                </NavLink>
                            </>
                        }
                        {
                            loggedIn &&
                            <>
                                <NavLink className="navbar-item" to='/profile'>
                                    Perfil
                                </NavLink>
                                <Link className="navbar-item"
                                    onClick={() => {
                                        dispatch(actions.user.logout());
                                        window.location.reload();
                                    }}>
                                    Cerrar Sesión
                                </Link>
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className='navbar-section' id="collapsible-menu">
                <div className="dropdown">
                    <div className='navbar-expand-btn'>
                        <button className="dropbtn" onClick={toggleMenu}><GrMenu size={32}/></button>
                    </div>
                    <div className="dropdown-content" style={{ display: 'none'}} id="collapsible-menu-options">
                        <NavLink className="navbar-item" to={{ pathname: '/sell' }}>
                            Vender
                        </NavLink>
                        <NavLink className="navbar-item" to={{ pathname: '/search', search: "?listingType=sale" }}>
                            Comprar
                        </NavLink>
                        <NavLink className="navbar-item" to={{ pathname: '/search', search: "?listingType=rent" }}>
                            Alquilar
                        </NavLink>
                        <NavLink className="navbar-item" to='/search-agent'>
                            Buscador de Agentes
                        </NavLink>
                        {
                            loggedIn &&
                            <NavLink className="navbar-item" to='/contacts'>
                                Mensajes
                            </NavLink>
                        }
                        <NavLink className="navbar-item" to='/help'>
                            Ayuda
                        </NavLink>
                        {
                            !loggedIn &&
                            <>
                                <NavLink className="navbar-item" to='/register'>
                                    Registrarse
                                </NavLink>
                                <NavLink className="navbar-item" to='/login'>
                                    Iniciar Sesión
                                </NavLink>
                            </>
                        }
                        {
                            loggedIn &&
                            <>
                                <NavLink className="navbar-item" to='/profile'>
                                    Perfil
                                </NavLink>
                                <Link className="navbar-item"
                                    onClick={() => {
                                        dispatch(actions.user.logout());
                                        window.location.reload();
                                    }}>
                                    Cerrar Sesión
                                </Link>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Navbar);
