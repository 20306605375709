import React from 'react';
import './ChatInput.css';
import {actions} from "../../store/modules";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../Input/Input';
import { GrSend } from 'react-icons/gr';

const OPACITY_ENABLED = 1.0
const OPACITY_DISABLED = 0.2

class ChatInput extends React.Component {

    constructor(props) {
        super(props)

        this.handleMessageChange = (message) => {
            this.props.updateMessage(message)
        }

        this.handleButtonPress = () => {
            if (this.props.message.trim().length == 0) return;
            this.props.sendMessage(this.props.chatId, this.props.message)
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.sendingError && this.props.sendingError) {
            alert('error', this.props.sendingError)
        }
    }

    render() {

        const sending = this.props.sending
        const isButtonDisabled = sending || this.props.message.trim().length == 0
        const opacity = isButtonDisabled ? OPACITY_DISABLED : OPACITY_ENABLED

        return (
        <div className='chatinput-container'>
            <Input
                className='chatinput-input'
                onChange={this.handleMessageChange}
                value={this.props.message}
                onSubmit={this.handleButtonPress}
                placeholder={'Type a message...'}
                placeholderTextColor={'#8F92A1'}
            />


            <div
                onClick={this.handleButtonPress}
                disabled={isButtonDisabled}
                style={{opacity: opacity}}
            >
                <GrSend color={'#88D203'} size={24} className='chatinput-icon'/>
            </div>
        </div>
        )
    }
}

ChatInput.propTypes = {
    sending: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
    updateMessage: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    sendingError: PropTypes.string,
    chatId: PropTypes.string,
}

const ChatInputContainer = props =>
    <ChatInput
        sending={props.sending}
        sendMessage={props.sendMessage}
        updateMessage={props.updateMessage}
        message={props.message}
        sendingError={props.sendingError}
        chatId={props.chatId} />

const mapStateToProps = state => ({
    sending: state.chat.sending,
    sendingError: state.chat.sendingError,
    message: state.chat.message
})


const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (chatId, text) =>
            dispatch(actions.chat.sendMessage(chatId, text)),
        updateMessage: (text) =>
            dispatch(actions.chat.updateMessage(text)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatInputContainer)
