import {appActionTypes} from './constants';
import {supportService} from "../../services/supportService";

export const sendSupportMessage = (name, phone, email, message) => {
    return function (dispatch) {
        dispatch({
            type: appActionTypes.SEND_SUPPORT_MESSAGE_REQUEST
        });

        return supportService.sendSupportMessage(name, phone, email, message).then(
            res => {
                dispatch({
                    type: appActionTypes.SEND_SUPPORT_MESSAGE_SUCCESS,
                });
                return res;
            },
            error => {
                dispatch({
                    type: appActionTypes.SEND_SUPPORT_MESSAGE_FAILURE,
                });
                return error;
            }
        );
    }
};
