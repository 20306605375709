import {http} from "../../utils/http";

export const propertyService = {
    searchPropertyById,
    searchPropertiesInsideArea,
    createProperty,
    updateProperty,
    deleteProperty,
    fetchPropertiesFromAgent
};

async function searchPropertyById(id) {
    return http.get('properties/' + id)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

async function searchPropertiesInsideArea(polygon, listingType = [], priceMinRent=null, priceMaxRent=null, priceMinSale=null, priceMaxSale=null, homeType = [], beds = null, baths = null) {
    return http.post('properties/search-area', {
        polygon,
        listingType,
        priceMinRent,
        priceMaxRent,
        priceMinSale,
        priceMaxSale,
        homeType,
        beds,
        baths
    })
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

async function createProperty(property) {
    return http.post('properties', {
        ...property
    })
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

async function updateProperty(_id, property) {
    return http.put('properties', {
        ...property,
        _id, 
    })
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

async function deleteProperty(id) {
    return http.delete('properties/' + id   )
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
async function fetchPropertiesFromAgent(agentId) {
    return http.get('properties/from-agent/' + agentId)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
