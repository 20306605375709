import React from 'react';
import './Sell.css';
import Input from "../../components/Input/Input";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Select from "../../components/Select/Select";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {actions} from "../../store";
import ImageUploading from 'react-images-uploading';
import FilterOption from "../../components/FilterOption/FilterOption";
import { RiFolderAddLine } from 'react-icons/ri';
import FadeLoader from "react-spinners/FadeLoader";
import SimpleMap from "../../components/Map/ReactMap";
import Autocomplete from 'react-autocomplete';
import cities from './colombia_cities';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import SearchBar from "../../components/SearchBar/SearchBar";
import stringSimilarity from 'string-similarity';

const axios = require('axios').default;

const LATITUDE = 4.60971;
const LONGITUDE = -74.08175;

const listingTypes = {
    SALE: "sale",
    RENT: "rent",
};

const homeTypes = {
    HOME: "home",
    APARTMENT: "apartment",
    //TERRACED_HOUSE: "terracedhouse",
    LAND: "land",
    //CONDOMINIUM: "condominium",
    //PREFABRICATED: "prefabricated",
    FARM: "farm",
    COMMERCIAL: "commercial",
    OTHER: "other"

};

class Sell extends React.Component{

    constructor(props)
    {
        super(props);

        this.state = {
            description: '',
            descriptionError: '',
            zipCode: '',
            zipCodeError: '',
            address: '',
            addressError: '',
            state: '',
            stateError: '',
            city: '',
            cityError: '',
            price: 0,
            priceError: '',
            listingType: listingTypes.SALE,
            homeType: homeTypes.HOME,
            area: 0,
            areaError: '',
            beds: 0,
            bedsError: '',
            baths: 0,
            bathsError: '',
            garage: false,
            pictures: [],
            picturesError: '',
            latitude: '',
            longitude: '',
            phone: '',
            phoneError: '',

            region: null,
            editing: false,
        };

        this._submit = this._submit.bind(this);
        this.setPrice = this.setPrice.bind(this);
        this.setArea = this.setArea.bind(this);
        this.setBeds = this.setBeds.bind(this);
        this.setBaths = this.setBaths.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
    }

    componentWillMount() {
        const main = this;
        let propertyId = new URLSearchParams(this.props.location.search).get("id")
        if (propertyId) {
            this.props.fetchPropertyById(propertyId).then(res => {
                main.setState({
                    description: res.description,
                    zipCode: res.zipCode,
                    address: res.address,
                    state: res.state,
                    city: res.city,
                    price: res.price,
                    listingType: res.listingType,
                    homeType: res.homeType,
                    area: res.area,
                    beds: res.beds,
                    baths: res.baths,
                    garage: res.garage,
                    pictures: res.pictures,
                    latitude: res.latitude,
                    longitude: res.longitude,
                    phone: res.phone,

                    region: null,
                    editing: true,
                    propertyId: res._id
                });
            });
        }

    }

    getLocation(address)
    {
        axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' +
            (address 
                ? encodeURIComponent(address)
                : (this.state.address ? this.state.address.split(' ').join('+') : '')
                    + (this.state.city ? ',+' + this.state.city.split(' ').join('+') : '')
                    + (this.state.state ? ',+' + this.state.state.split(' ').join('+') : ''))
            + '&key=AIzaSyAYNGTQVlz05OTWG8l5TJA6zCnlEFQUeEA')
            .then(
                res => {
                    this.setState({
                        region: {
                            latitude: res.data.results[0]?.geometry?.location.lat,
                            longitude: res.data.results[0]?.geometry?.location.lng,
                        },
                        latitude: res.data.results[0]?.geometry?.location.lat,
                        longitude: res.data.results[0]?.geometry?.location.lng,
                    });

                    const address = res.data.results[0]?.address_components;

                    let setCity = false;
                    // try to match the address to a city and state from cities
                    for (let i = 0; i < address.length; i++) {
                        if (!setCity) {
                            const city = cities.find(item => {
                                return stringSimilarity.compareTwoStrings(item.city || '', address[i].long_name) > 0.6;
                            });

                            if (city) {
                                for (let j = 0; j < address.length; j++) {
                                    if (stringSimilarity.compareTwoStrings(city.department || '', address[j].long_name) > 0.6) {
                                        this.setState({
                                            city: city.city,
                                            state: city.department,
                                        });
                                        setCity = true;
                                        break;
                                    }
                                }
                            }
                        }

                        if (address[i].types.includes('postal_code')) {
                            this.setState({
                                zipCode: address[i].long_name,
                            });
                        }
                    }

                }, err => console.log(err)).catch(err => console.log(err));
    }

    openGallery()
    {

    }

    setHomeType(homeType) {
        if (homeType === homeTypes.LAND) {
            this.setState({beds: 0, baths: 0, garage: false});
        }
        this.setState({homeType: homeType});
    }

    mapHomeTypesToSelect() {
        return Object.entries(homeTypes).map(entry => {
            let label = '';
            if (entry[1] === 'home') label = 'Casa';
            if (entry[1] === 'apartment') label = 'Apartamento';
            if (entry[1] === 'land') label = 'Tereno';
            if (entry[1] === 'farm') label = 'Finca';
            if (entry[1] === 'commercial') label = 'Local Comercial';
            if (entry[1] === 'other') label = 'Otro';

            return <option label={label} value={entry[1]}/>;
        });
    }


    imageUpload(imageList, addUpdateIndex) {
        // data for submit
        console.log(imageList, addUpdateIndex);
        this.setState({pictures: imageList, picturesError: ''});
    };

    getCities() {
        return cities.sort((a,b) => a.department.localeCompare(b.department)).map(city => { 
            return {label: city.city, value: city};
        });
    }

    setPrice(price) {
        if (price === '' || price === 0 || price === '0' || isNaN(price)) {
            this.setState({price: '', priceError: 'Precio no puede estar vacío'});
        } else {
            this.setState({price: price, priceError: ''});
            this.setState({priceError: ''});
        }
    }

    setArea(area) {
        if (area === '' || area === 0 || area === '0' || isNaN(area)) {
            this.setState({area: '', areaError: 'Area no puede estar vacío'});
        } else {
            this.setState({area: area, areaError: ''});
        }
    }

    setBeds(beds) {
        if (beds === '' || beds === 0 || beds === '0' || isNaN(beds)) {
            this.setState({beds: '', bedsError: 'Habitaciones no puede estar vacío'});
        } else {
            this.setState({beds: beds, bedsError: ''});
        }
    }

    setBaths(baths) {
        if (baths === '' || baths === 0 || baths === '0' || isNaN(baths)) {
            this.setState({baths: '', bathsError: 'Baños no puede estar vacío'});
        } else {
            this.setState({baths: baths, bathsError: ''});
        }
    }

    setPhone(phone) {
        if ((phone && phone !== '') && !isValidPhoneNumber(phone)) {
            this.setState({
                phone: phone,
                phoneError: 'Ingrese un número de teléfono válido'
            });
        } else {
            this.setState({
                phone: phone,
                phoneError: ''
            });
        }
    }

    render(){
        const {region, description, zipCode, address, state, city, price, listingType, homeType, area, beds, baths, garage, pictures, latitude, longitude, phone} = this.state;

        return (
            <div className='sell-page'>
                <div className="sell-header">
                </div>

                <div className='sell-container'>
                    <div className='sell-title'>Detalles de Dirección</div>

                    <div className='sell-map-row'>
                        <SimpleMap
                            center={{ lat: latitude || LATITUDE, lng: longitude || LONGITUDE}} 
                            markers={latitude && longitude ? [{latitude, longitude, price}] : []}
                            onMarkerClick={() => {}}
                            draggable={false}
                        />
                    </div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'Dirección de Calle'}</div>

                            <SearchBar
                                containerStyle={{
                                    height: 'fit-content',
                                    width: '100%', 
                                    maxWidth: 500,
                                    border: 'none',
                                    marginTop: 8,
                                    borderBottom: '1px solid #8F92A177',
                                    boxSizing: 'border-box',
                                }}
                                inputStyle={{
                                    padding: 0,
                                    margin: 8, 
                                    width: '100%',
                                    color: 'black'
                                }}
                                onSubmit={address => {
                                    this.setState({address, addressError: ''});
                                    this.getLocation(address);
                                }}
                                defaultValue={address}
                                suggestInput={true}
                            />

                            <div className='input-error'>{this.state.addressError}</div>
                        </div>
                    </div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'Ciudad'}</div>

                            {/* <Autocomplete
                                getItemValue={(item) => {
                                    return item.value.city + ', ' + item.value.department;              
                                }}
                                items={this.getCities()}
                                renderItem={(item, isHighlighted) =>
                                    <div style={{ 
                                        background: isHighlighted ? 'lightgray' : 'white',
                                        padding: '5px 10px',
                                        fontSize: '14px',
                                        cursor: 'pointer'
                                    }}>
                                      {item.label}, {item.value.department}
                                    </div>
                                }
                                inputProps={{placeholder: 'Seleccionar ciudad...'}}
                                menuStyle={{
                                    borderRadius: '3px',
                                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                    padding: '2px 0',
                                    fontSize: '90%',
                                    overflow: 'auto',
                                    zIndex: 9999,
                                    maxHeight: '50%',
                                }}
                                placeholder={'Procure por ciudad...'}
                                type={'text'}
                                value={city}
                                onChange={(evt) => {
                                    this.setState({city: evt.target.value});
                                }}
                                onBlur={this.getLocation}
                                onSelect={(value) => {
                                    const city = value.split(',')[0];
                                    const state = value.split(',')[1];
                                    this.setState({city: city, state: state});
                                }}
                                shouldItemRender={(item, value) => {
                                    return item.label.toLowerCase().indexOf(value?.toLowerCase()) > -1;
                                }}
                                renderInput={(props) => {
                                    return <input {...props} className="input-el" />
                                }}
                            />
                         */}
                            <Input
                                type={'text'}
                                value={city}
                                onChange={(city) => this.setState({city})}
                                onBlur={this.getLocation}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'Departamento'}</div>

                            <Input
                                //placeholder={'El departamento se puebla después de seleccionar la ciudad'}
                                type={'text'}
                                value={state}
                                onChange={(state) => this.setState({state})}
                                onBlur={this.getLocation}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'Tipo de Casa'}</div>

                            <Select
                                placeholder={{label: 'Seleccionar tipo de casa...', value: null}}
                                value={homeType}
                                onChange={(homeType) => this.setState({homeType})}
                            >
                                {this.mapHomeTypesToSelect()}
                            </Select>
                        </div>
                    </div>

                    <div className='sell-title'>Precio</div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'insertar precio'}</div>

                            <Input
                                keyboardType={'decimal-pad'}
                                placeholder={' '}
                                type={'number'}
                                value={price}
                                errorMessage={this.state.priceError}
                                onChange={this.setPrice}/>
                        </div>

                        <div className='sell-input-container'>
                            <div className='sell-label'>{'En Venta/Alquilar'}</div>

                            <Select
                                onChange={(listingType) => this.setState({listingType})}
                                value={listingType}
                            >
                                <option label={'En Venta'}
                                        value={listingTypes.SALE}/>
                                <option label={'Alquilar'}
                                        value={listingTypes.RENT}/>
                            </Select>
                        </div>
                    </div>



                    <div className='sell-title'>Agregar Fotos</div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <ImageUploading
                                multiple
                                value={pictures}
                                onChange={this.imageUpload}
                                dataURLKey="data_url"
                            >
                                {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                    }) => (
                                        <div {...dragProps}>
                                            <div
                                                className='sell-upload-area'
                                                style={isDragging ? {backgroundColor: 'grey'} : {backgroundColor: 'white'}}
                                                onClick={onImageUpload}
                                            >
                                                <RiFolderAddLine color={'#8F92A1'} size={48}/>
                                                <div className='sell-upload-label'>
                                                    Suelte sus fotos aquí o clic para cargar
                                                </div>
                                            </div>
                                            <div className='sell-pics-row'>
                                                {imageList.map((image, index) => (
                                                    <PictureSquare key={index} image={image['data_url'] || image} onDelete={() => {
                                                        let list = pictures;
                                                        list.splice(index, 1);
                                                        this.setState({pictures: list});
                                                    }}/>
                                                ))}
                                            </div>
                                        </div>
                                )}
                            </ImageUploading>

                            <div className='input-error'>{this.state.picturesError}</div>
                        </div>
                    </div>

                    <div className='sell-title'>Características de la Casa</div>

                    {
                        homeType !== homeTypes.LAND &&         
                        <div className='sell-form-row'>
                            <div className='sell-input-container'>
                                <div className='sell-label'>{'habitaciones'}</div>

                                <Input
                                    keyboardType={'number-pad'}
                                    placeholder={' '}
                                    isPassword={false}
                                    value={beds}
                                    type={'number'}
                                    errorMessage={this.state.bedsError}
                                    onChange={this.setBeds}/>
                            </div>
                        </div>
                    }

                    {
                        homeType !== homeTypes.LAND &&
                        <div className='sell-form-row'>
                            <div className='sell-input-container'>
                                <div className='sell-label'>{'baños'}</div>

                                <Input
                                    type={'number'}
                                    placeholder={' '}
                                    value={baths}
                                    errorMessage={this.state.bathsError}
                                    onChange={this.setBaths}/>
                            </div>
                        </div>
                    }

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'área (m2)'}</div>

                            <Input
                                keyboardType={'number-pad'}
                                placeholder={' '}
                                isPassword={false}
                                value={area}
                                type={'number'}
                                errorMessage={this.state.areaError}
                                onChange={this.setArea}/>
                        </div>
                    </div>

                    {
                        homeType !== homeTypes.LAND &&
                        <div className='sell-form-row'>
                            <div className='sell-input-container'>
                                <div className='sell-label'>{'Garaje'}</div>

                                <FilterOption label={'Garaje'} selected={this.state.garage} onPress={() => this.setState({garage: !garage})} containerStyle={{justifyContent: 'flex-start'}}/>
                            </div>
                        </div>
                    }

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>{'describe tu casa'}</div>

                            <Input
                                placeholder={' '}
                                value={description}
                                onChange={(description) => this.setState({description, descriptionError: ''})}
                                errorMessage={this.state.descriptionError}
                            />
                        </div>
                    </div>


                    <div className='sell-title'>Información de Contacto (Opcional)</div>

                    <div className='sell-form-row'>
                        <div className='sell-input-container'>
                            <div className='sell-label'>Número de teléfono móvil</div>
                        
                            <PhoneInput 
                                className='input-el' 
                                countryCallingCodeEditable={false} 
                                international 
                                defaultCountry='CO' 
                                value={phone} 
                                onChange={(phone) => this.setPhone(phone)}
                            />
                            {
                                !!this.state.phoneError &&
                                <div className='input-error'>
                                    {this.state.phoneError}
                                </div>
                            }              
                        </div>
                    </div>

                    <div className='sell-form-row'>
                        <PrimaryButton label={'Enviar'} onClick={this._submit}/>
                    </div>

                    <FadeLoader
                        color={'#88D203'}
                        loading={this.props.creatingProperty}
                    />
                </div>
            </div>
        );
    }

    _submit() {
        const {area, beds, address, price, homeType, state, garage, baths, description, pictures, listingType, zipCode, city, latitude, longitude, propertyId, editing, phone} = this.state;

        let errFlag = false;
        let fieldsWithErrors = [];

        if (address?.length === 0 || !address) {
            this.setState({addressError: 'Este campo es requerido'});
            fieldsWithErrors.push('Dirección');
            errFlag = true;
        }
        if (homeType === null) {
            this.setState({homeTypeError: 'Este campo es requerido'});
            fieldsWithErrors.push('Tipo de propiedad');
            errFlag = true;
        }
        if (price <= 0 || isNaN(price) || !price) {
            this.setState({priceError: 'Este campo es requerido'});
            fieldsWithErrors.push('Precio');
            errFlag = true;
        }
        if (pictures?.length === 0) {
            this.setState({picturesError: 'Se requiere al menos una foto.'});
            fieldsWithErrors.push('Fotos');
            errFlag = true;
        }
        if ((beds <= 0 || isNaN(beds) || !beds) && homeType !== homeTypes.LAND) {
            this.setState({bedsError: 'Este campo es requerido'});
            fieldsWithErrors.push('Habitaciones');
            errFlag = true;
        }
        if ((baths <= 0 || isNaN(baths) || !baths) && homeType !== homeTypes.LAND) {
            this.setState({bathsError: 'Este campo es requerido'});
            fieldsWithErrors.push('Baños');
            errFlag = true;
        }
        if (area <= 0 || isNaN(area) || !area) {
            this.setState({areaError: 'Este campo es requerido'});
            fieldsWithErrors.push('Área');
            errFlag = true;
        }
        if (description?.length === 0 || !description) {
            this.setState({descriptionError: 'Este campo es requerido'});
            fieldsWithErrors.push('Descripción');
            errFlag = true;
        }
        if (phone && !isValidPhoneNumber(phone)) {
            this.setState({phoneError: 'Número de teléfono inválido'});
            fieldsWithErrors.push('Número de teléfono');
            errFlag = true;
        }

        if (errFlag) {
            alert(
                'Error\nPor favor, complete todos los campos y corrija los errores.' + 
                '\n\nCampos con errores:\n' + fieldsWithErrors.join(', ')
            );
            return;
        }


        if (!editing) {
            this.props.createProperty({
                address,
                city,
                state,
                zipCode,
                price,
                listingType,
                pictures,
                homeType,
                beds,
                baths,
                area,
                garage,
                description,
                latitude,
                longitude,
                phone
            }).then(
                res => {
                    this.props.history.push(this.props.history.push("/search?property=" + res._id));
                },
                error => {
                    console.log(error)
                }
            );
        } else {
            this.props.updateProperty(propertyId, 
                {
                    address, 
                    city, 
                    state, 
                    zipCode, 
                    price, 
                    listingType, 
                    pictures, 
                    homeType, 
                    beds, 
                    baths, 
                    area, 
                    garage, 
                    description, 
                    latitude, 
                    longitude,
                    phone
                }).then(
                res => {
                    console.log(res)
                    this.props.history.push(this.props.history.push("/search?property=" + res._id));
                },
                error => {
                    console.log(error)
                    if (error.response.status === 500 && error.response.data.error.includes('duplicate key error')) {
                        alert('Ya existe una propiedad con esta dirección');
                    }
                }
            );
        }
    }
}

function AddPictureButton ({onPress}) {
    return (
        <div>
        {
            /*

        <div style={pictureStyles.square} onClick={onPress}>
            <div style={pictureStyles.addIcon}>
                <Icon name={'plus'} size={24} color={'white'}/>
            </div>
        </div>
             */
        }
        </div>
    );
}

function PictureSquare ({image, onDelete}) {
    return (
        <div className='sell-pic-container'>
            <img className='sell-pic' src={image} />
            <div className='sell-pic-cross-container' onClick={onDelete}>
                <span className='sell-pic-cross'>&#10005;</span>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        creatingProperty: state.property.creatingProperty,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createProperty: (property) =>
            dispatch(actions.property.createProperty(property)),
        updateProperty: (id, property) =>
            dispatch(actions.property.updateProperty(id, property)),
        fetchPropertyById: (id) =>
            dispatch(actions.property.fetchPropertyById(id)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sell));
