import React, { useState, useEffect } from 'react';
import '../Login/Login.css';
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Input from "../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store";
import { useHistory } from "react-router-dom";
import 'react-phone-number-input/style.css';
import OtpInput from 'react-otp-input';


function RecoverPassword() {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.user.loggedIn);
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState(null);
    const [password, setPassword] = useState(null);
    const [step, setStep] = useState(0);
    const history = useHistory();

    useEffect(function () {
        if (loggedIn) {
            history.push("/");
        }
    }, []);

    function _nextStep() {
        if (step === 0) {
            if (isValidPhoneNumber(phone)) {
                setStep(step + 1);
                dispatch(actions.user.requestConfirmationCode(phone, null, false));
            } else {
                return alert('Por favor, complete todos los campos y corrija los errores');
            }
        } else if (step === 1) {
            setStep(step + 1);
        }else if (step === 2) {
            submit();
        }
    }

    function submit() {
        if((!password || password.length === 0) || (!otp || otp.length === 0)) {
            alert('Las contraseñas no coinciden');
        } else {
            dispatch(actions.user.changePassword(phone, password, otp)).then(a => {
                if (a === 401) {
                    alert('El código de verificación es incorrecto');
                } else {
                    alert('Tu contraseña ha sido cambiada');
                    history.push("/login");
                }
            })
        }
    }

    return (
        <form className='login-container' onSubmit={(evt) => {
            evt.preventDefault();
            _nextStep();
        }}>

            <Link to="/login">
                <img src={'logo-wo-bg.png'} className="login-logo" alt="logo" />
            </Link>

            <div className='login-form-content'>
                <div className='login-title'>
                    ¡Bienvenido!
                </div>

                <div className='login-subtitle'>
                    { step === 0 && 'Ingrese su número de teléfono móvil' }
                    { step === 1 && 'Ingrese el código PIN que recibió por WhatsApp' }
                    { step === 2 && 'Ingrese su nueva contraseña' }
                </div>

                {
                    step === 0 &&
                    <div className='input-container'>
                        <div className='input-label'>
                            Número de teléfono móvil
                        </div>
                        <PhoneInput className='input-el' countryCallingCodeEditable={false} international defaultCountry='CO' value={phone} onChange={setPhone} />
                        {
                            phone?.length > 0 && !isValidPhoneNumber(phone) &&
                            <div className='input-error'>
                                Ingrese un número de teléfono válido
                            </div>
                        }
                    </div>
                }
                {
                    step === 1 &&
                    <>
                        <OtpInput value={otp} onChange={setOtp} numInputs={4} placeholder={'0000'} inputStyle={'input-el input-el-otp'} isInputNum={true} />
                    </>
                }
                {
                    step === 2 &&
                    <>
                        <Input placeholder={'● ● ● ● ● ●'} type={'password'} label='contraseña' onChange={setPassword} id={'password'} errorMessage={password?.length > 0 && password?.length < 6 && 'Tu contraseña es demasiado corta'} />
                    </>
                }
            </div>

            {
                step === 0 &&
                <>
                    <PrimaryButton label='Próximo paso' onClick={_nextStep} />
                </>
            }
            {
                step === 1 &&
                <>
                    <PrimaryButton label='Próximo paso' onClick={_nextStep} />
                </>
            }
            {
                step === 2 &&
                <>
                    <PrimaryButton label='Confirmar' onClick={submit} />
                </>
            }

        </form>
    );
}

export default RecoverPassword;
