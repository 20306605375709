import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import './FilterOption.css';

function FilterOption({ onPress, label, selected, containerStyle }) {
    return (
        <div className='filteroption-button' style={containerStyle} onClick={onPress}>
            {
                typeof label === 'string' ?
                    <div className='filteroption-label'>{label}</div>
                    :
                    label
            }
            <HiCheckCircle color={selected? '#88D203' : '#E0E0E0'} size={24} onPress={onPress}/>
        </div>
    );
}

export default FilterOption;
