import React from 'react';
import './ChatMessage.css';
import TimeAgo from 'javascript-time-ago';
import es from "javascript-time-ago/locale/es";

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function ChatMessage({message, me, contactImage, timestamp}){
    function renderImage() {
        if (!me) {
            if (contactImage) {
                return (
                    <img src={contactImage} className='chatmessage-image' alt='user picture'/>
                );
            } else {
                return (
                    <img src={'default-user-2.png'} className='chatmessage-image' alt='user picture'/>
                );
            }
        }
    }

    return (
        <div className='chatmessage-container'>
            {
                renderImage()
            }

            <div className={me? 'chatmessage-contentViewMe' : 'chatmessage-contentViewOther'}>
                <div className={me? 'chatmessage-messageTextMe' : 'chatmessage-messageTextOther'}>
                    {message}
                </div>
                <div className={'chatmessage-messageTime'}>
                    {timeAgo.format(Date.parse(timestamp))}
                </div>
            </div>
        </div>
    );
}

export default ChatMessage;
