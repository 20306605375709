import React, {useState, useEffect} from 'react';
import './DeleteAccountModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import OtpInput from 'react-otp-input';
import "react-input-range/lib/css/index.css";
import {actions} from "../../store/modules";

Modal.setAppElement(document.getElementById('root'));

function DeleteAccountModal({isOpen, onRequestClose}) {

    const dispatch = useDispatch();

    const [otp, setOtp] = useState(null);

    function deleteAccount() {
        dispatch(actions.user.deleteAccount(otp)).then(a => {
            alert('Tu cuenta ha sido eliminada');
            dispatch(actions.user.logout());
            window.location.reload();
        }).catch(e => {
            if (e.status === 401) {
                alert('El código de verificación es incorrecto');
                return;
            }
            alert('Ha ocurrido un error');
        });
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Eliminar cuenta'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='deleteaccountmodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <div className='deleteaccountmodal-content'>
                <button onClick={onRequestClose} className='deleteaccountmodal-close-btn'>
                    <GrClose/>
                </button>

                <div className='deleteaccountmodal-title'>
                    Eliminar cuenta
                </div>

                <div className='deleteaccountmodal-subtitle'>
                    Atención: Esta acción no se puede deshacer.
                    <br/>
                    Para eliminar tu cuenta, ingresa el código de verificación que te enviamos por WhatsApp.
                </div>

                <OtpInput value={otp} onChange={setOtp} numInputs={4} placeholder={'0000'} inputStyle={'input-el input-el-otp'} isInputNum={true} />

                <PrimaryButton label={'Confirmar'} onClick={deleteAccount}/>

            </div>
        </Modal>
    );
}

export default DeleteAccountModal;
