import React, {useState} from 'react';
import './SendMessage.css';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import Input from "../Input/Input";


function SendMessage({user}) {
    //const [messageMode, setMessageMode] = useState(false);
    const [name, setName] = useState(user? user.username : null);
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState(user? user.email : null);

    return (
        <div className='sendmessage-container'>
            <div className='sendmessage-title'>
                Contactar
            </div>

            <Input placeholder={'Anne'} type={'text'} label='Nombre' onChange={setName} value={name}/>
            <Input placeholder={'ejemplo@ejemplo.com'} type={'email'} label='E-mail' onChange={setEmail} value={email}/>
            <Input placeholder={'Escribe tu mensaje...'} type={'text'} label='Mensagem' onChange={setMessage} value={message}/>

            <PrimaryButton label={'Enviar'}/>
        </div>

    );
}

export default SendMessage;
