import React, {useState, useEffect} from 'react';
import './ListingTypeFilterModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import FilterOption from "../FilterOption/FilterOption";
import {Marker} from "react-google-maps";

Modal.setAppElement(document.getElementById('root'));

function ListingTypeFilterModal({isOpen, onRequestClose}) {

    const dispatch = useDispatch();
    const listingTypeFilter = useSelector(state => state.property.listingTypeFilter);

    function changeFilter(listingType) {
        let currentFilter = Object.assign([], listingTypeFilter);

        if(!Array.isArray(currentFilter)) currentFilter = [currentFilter];

        if (currentFilter.length === 0 ){
            currentFilter.push('sale');
            currentFilter.push('rent');
        }

        const typeIdx = currentFilter.indexOf(listingType);

        if (typeIdx > -1) {
            currentFilter.splice(typeIdx, 1);
        } else {
            currentFilter.push(listingType);
        }

        dispatch(actions.property.changeFilters(currentFilter))
    }

    function filtersContain(filter) {
        return listingTypeFilter.indexOf(filter)> -1;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Listing Type Filter'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='listingtypefiltermodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <div className='listingtypefiltermodal-content'>
                <button onClick={onRequestClose} className='listingtypefiltermodal-close-btn'>
                    <GrClose/>
                </button>

                <div className='listingtypefiltermodal-options'>
                    <div className='listingtypefiltermodal-option-row'>
                        <img src={'/sale-marker.png'} style={{width: 39, height: 39}} alt={'sale'}/>
                        <FilterOption label={'En Venta'} selected={filtersContain("sale") || listingTypeFilter.length === 0} onPress={() => changeFilter("sale")}/>
                    </div>

                    <div className='listingtypefiltermodal-option-row'>
                        <img src={'/rent-marker.png'} style={{width: 39, height: 39}} alt={'rent'}/>
                        <FilterOption label={'Alquilar'} selected={filtersContain("rent") || listingTypeFilter.length === 0} onPress={() => changeFilter("rent")}/>
                    </div>
                </div>

                <PrimaryButton label={'Aplicar'} onClick={onRequestClose}/>
            </div>
        </Modal>
    );
}

export default ListingTypeFilterModal;
