export const userActionTypes = {
    LOGIN_REQUEST: 'USER/LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER/LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER/LOGIN_FAILURE',
    LOGOUT: 'USER/LOGOUT',

    REGISTER_REQUEST: 'USER/REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER/REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER/REGISTER_FAILURE',

    OTP_REQUEST: 'USER/OTP_REQUEST',
    OTP_SUCCESS: 'USER/OTP_SUCCESS',
    OTP_FAILURE: 'USER/OTP_FAILURE',

    CHECK_EMAIL_AVAILABLE_REQUEST: 'USER/CHECK_EMAIL_AVAILABLE_REQUEST',
    CHECK_EMAIL_AVAILABLE_SUCCESS: 'USER/CHECK_EMAIL_AVAILABLE_SUCCESS',
    CHECK_EMAIL_AVAILABLE_FAILURE: 'USER/CHECK_EMAIL_AVAILABLE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'USER/CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USER/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USER/CHANGE_PASSWORD_FAILURE',

    UPDATE_USER_REQUEST: 'USER/UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'USER/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'USER/UPDATE_USER_FAILURE',

    FETCH_USER_REQUEST: 'USER/FETCH_USER_REQUEST',
    FETCH_USER_SUCCESS: 'USER/FETCH_USER_SUCCESS',
    FETCH_USER_FAILURE: 'USER/FETCH_USER_FAILURE',

    FETCH_USER_BY_ID_REQUEST: 'USER/FETCH_USER_BY_ID_REQUEST',
    FETCH_USER_BY_ID_SUCCESS: 'USER/FETCH_USER_BY_ID_SUCCESS',
    FETCH_USER_BY_ID_FAILURE: 'USER/FETCH_USER_BY_ID_FAILURE',

    FETCH_MY_LISTINGS_REQUEST: 'USER/FETCH_MY_LISTINGS_REQUEST',
    FETCH_MY_LISTINGS_SUCCESS: 'USER/FETCH_MY_LISTINGS_SUCCESS',
    FETCH_MY_LISTINGS_FAILURE: 'USER/FETCH_MY_LISTINGS_FAILURE',

    FETCH_FAVORITES_REQUEST: 'USER/FETCH_FAVORITES_REQUEST',
    FETCH_FAVORITES_SUCCESS: 'USER/FETCH_FAVORITES_SUCCESS',
    FETCH_FAVORITES_FAILURE: 'USER/FETCH_FAVORITES_FAILURE',

    ADD_FAVORITE_REQUEST: 'USER/ADD_FAVORITE_REQUEST',
    ADD_FAVORITE_SUCCESS: 'USER/ADD_FAVORITE_SUCCESS',
    ADD_FAVORITE_FAILURE: 'USER/ADD_FAVORITE_FAILURE',

    REMOVE_FAVORITE_REQUEST: 'USER/REMOVE_FAVORITE_REQUEST',
    REMOVE_FAVORITE_SUCCESS: 'USER/REMOVE_FAVORITE_SUCCESS',
    REMOVE_FAVORITE_FAILURE: 'USER/REMOVE_FAVORITE_FAILURE',

    DELETE_ACCOUNT_REQUEST: 'USER/DELETE_ACCOUNT_REQUEST',
    DELETE_ACCOUNT_SUCCESS: 'USER/DELETE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_FAILURE: 'USER/DELETE_ACCOUNT_FAILURE',
};
