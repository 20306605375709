export default function homeTypeLabel(t) {
    if (t === 'home') return 'Casa';
    if (t === 'apartment') return 'Apartamento';
    //if (t === 'terracedhouse') return 'Casas Adosadas';
    if (t === 'land') return 'Lote / Terreno';
    //if (t === 'condominium') return 'Condominio';
    //if (t === 'prefabricated') return 'Fabricado';
    if (t === 'farm') return 'Finca';
    if (t === 'commercial') return 'Local Comercial';
    if (t === 'other') return 'Otro';
    else return t;
}