export const chatActionTypes = {
    CHAT_MESSAGE_LOADING: 'CHAT/CHAT_MESSAGE_LOADING',
    CHAT_MESSAGE_SUCCESS: 'CHAT/CHAT_MESSAGE_SUCCESS',
    CHAT_MESSAGE_ERROR: 'CHAT/CHAT_MESSAGE_ERROR',
    CHAT_MESSAGE_UPDATE: 'CHAT/CHAT_MESSAGE_UPDATE',

    CHAT_LOAD_MESSAGES_SUCCESS: 'CHAT/CHAT_LOAD_MESSAGES_SUCCESS',
    CHAT_LOAD_MESSAGES_ERROR: 'CHAT/CHAT_LOAD_MESSAGES_ERROR',

    CHATS_LOAD_REQUEST: 'CHAT/CHATS_LOAD_REQUEST',
    CHATS_LOAD_SUCCESS: 'CHAT/CHATS_LOAD_SUCCESS',
    CHATS_LOAD_ERROR: 'CHAT/CHATS_LOAD_ERROR',

    CHAT_CREATION_REQUEST: 'CHAT/CHAT_CREATION_REQUEST',
    CHAT_CREATION_SUCCESS: 'CHAT/CHAT_CREATION_SUCCESS',
    CHAT_CREATION_FAILURE: 'CHAT/CHAT_CREATION_FAILURE',
};
