import React, {useEffect, useState, useRef} from 'react';
import './Search.css';
import SearchBar from "../../components/SearchBar/SearchBar";
import {markerTypes} from "../../components/Map/Map";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import FilterButton from "../../components/FilterButton/FilterButton";
import PropertyModal from "../../components/PropertyModal/PropertyModal";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import {useLocation} from "react-router-dom";
import ListingTypeFilterModal from "../../components/ListingTypeFilterModal/ListingTypeFilterModal";
import HomeTypeFilterModal from "../../components/HomeTypeFilterModal/HomeTypeFilterModal";
import BedsBathsFilterModal from "../../components/BedsBathsFilterModal/BedsBathsFilterModal";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import PriceFilterModal from "../../components/PriceFilterModal/PriceFilterModal";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FaPencilAlt, FaEraser, FaMap, FaList } from 'react-icons/fa';
import FadeLoader from "react-spinners/FadeLoader";
import SimpleMap from "../../components/Map/ReactMap";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";

const google = window.google;

const LATITUDE = 4.1156735 -72.9301367;
const LONGITUDE = -72.9301367;
const LATITUDE_DELTA = 10;
const LONGITUDE_DELTA = 10;
const SPACE = 0.01;

let id = 0;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Search(){
    let query = useQuery();

    const mapRef = useRef();
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);

    const history = useHistory();

    const dispatch = useDispatch();
    const properties = useSelector(state => state.property.propertiesInArea);
    const listingTypeFilter = useSelector(state => state.property.listingTypeFilter);
    const priceMinFilter = useSelector(state => state.property.priceMinFilter);
    const priceMaxFilter = useSelector(state => state.property.priceMaxFilter);
    const homeTypeFilter = useSelector(state => state.property.homeTypeFilter);
    const bedsFilter = useSelector(state => state.property.bedsFilter);
    const bathsFilter = useSelector(state => state.property.bathsFilter);

    const fetchingPropertiesInArea = useSelector(state => state.property.fetchingPropertiesInArea);

    const [modalIsOpen, setModelIsOpen] = useState(false);
    const [propertyForModal, setPropertyForModal] = useState(null);

    const [mapDragListener, setMapDragListener] = useState(null);
    const [drawing, setDrawing] = useState(null);
    const [polygons, setPolygons] = useState(null);

    const [mapCenter, setMapCenter] = useState(null);

    const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1024);
    const [showMap, setShowMap] = useState(false);

    const [listingTypeFilterOpen, setListingTypeFilterOpen] = useState(false);
    const [priceFilterOpen, setPriceFilterOpen] = useState(false);
    const [bedsBathsFilterOpen, setBedsBathsFilterOpen] = useState(false);
    const [homeTypeFilterOpen, setHomeTypeFilterOpen] = useState(false);

    const [markerType, setMarkerType] = useState(markerTypes.PRICE_MARKER);

    useEffect(function () {
        let propertyFromQuery = query.get('property');
        if (propertyFromQuery) {
            dispatch(actions.property.fetchPropertyById(propertyFromQuery)).then(
                p => openModal(p)
            );
        }

        let addressFromQuery = decodeURIComponent(query.get('address'));
        if (addressFromQuery && addressFromQuery !== 'null') {
            getLocation(addressFromQuery)
        }
        //map.controls[google.maps.ControlPosition.TOP_RIGHT].push();


        // resize listener
        function handleResize() {
            if (window.innerWidth >= 1024) {
                setIsLaptop(true);
            } else {
                setIsLaptop(false);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(function () {
        let addressFromQuery = decodeURIComponent(query.get('address'));
        if (addressFromQuery && addressFromQuery !== 'null') {
            getLocation(addressFromQuery)
        }
    }, [map]);

    //use effect for url location changes
    useEffect(function () {
        let listingTypeFromQuery = query.get('listingType');
        if (!listingTypeFromQuery) {
            listingTypeFromQuery = ['rent', 'sale'];
        } else if (!Array.isArray(listingTypeFromQuery)){
            listingTypeFromQuery = [listingTypeFromQuery];
        }
        dispatch(actions.property.changeFilters(listingTypeFromQuery));
        dispatch(actions.property.searchPropertiesInsideArea(null));
    }, [query.get('listingType')]);

    function handleMapsApiLoaded (map, maps) {
        //console.log(map)
        //console.log(maps.event)
        setMap(map);
        setMaps(maps);
    }

    function getLocation(address) {
        let urlQuery = new URLSearchParams();
        if (query.get('listingType')) urlQuery.append('listingType', query.get('listingType'));
        if (query.get('property')) urlQuery.append('property', query.get('property'));
        urlQuery.append('address', encodeURIComponent(address));

        history.push({
            pathname: '/search',
            search: "?" + urlQuery.toString()
        });

        axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='
            + (address? encodeURIComponent(address.split(' ')) : '')
            + '&types=(regions)&components=country:co&key=AIzaSyAYNGTQVlz05OTWG8l5TJA6zCnlEFQUeEA')
            .then(
                res => {
                    res.data.results.map((r, i) => console.log('results', i, r))
                    const geometry = res.data.results[0]?.geometry;
                    if (!geometry) return;
                    const northeast = geometry.viewport?.northeast;
                    const southwest = geometry.viewport?.southwest;

                    //this.mapRef.setMapBoundaries({latitude: northeast.lat, longitude: northeast.lng},{latitude: southwest.lat, longitude: southwest.lng});


                    if (northeast && southwest) {
                        map.fitBounds(new google.maps.LatLngBounds(southwest, northeast));
                        refresh();
                    } else {
                        /*map.animateToRegion({
                            latitude: geometry.location.lat,
                            longitude: geometry.location.lng,
                        })*/
                    }
                    /*
                    this.mapRef.animateToRegion({
                        latitude: res.data.results[0].geometry.location.lat,
                        longitude: res.data.results[0].geometry.location.lng,
                    })
                     */
                }, err => console.log(err)).catch(err => console.log(err));
    }

    function refresh(usePolygon=true) {
        if (!polygons || polygons.length === 0 || !usePolygon) {
            /*let latitudeDelta = Math.abs(map.getBounds().getNorthEast().lat() - map.getBounds().getSouthWest().lat()) / 2;
            let longitudeDelta = Math.abs(map.getBounds().getNorthEast().lng() - map.getBounds().getSouthWest().lng()) / 2;
            let center = {latitude: map.getCenter().lat(), longitude: map.getCenter().lng()};

            const coordinates = [
                {
                    latitude: center.latitude + latitudeDelta,
                    longitude: center.longitude - longitudeDelta
                },
                {
                    latitude: center.latitude - latitudeDelta,
                    longitude: center.longitude - longitudeDelta
                },
                {
                    latitude: center.latitude - latitudeDelta,
                    longitude: center.longitude + longitudeDelta
                },
                {
                    latitude: center.latitude + latitudeDelta,
                    longitude: center.longitude + longitudeDelta
                },
            ];

            dispatch(actions.property.searchPropertiesInsideArea(coordinates));*/
            dispatch(actions.property.searchPropertiesInsideArea(null));
        }
    }

    function onCenterChanged(shouldRefresh) {
        setMapCenter({lat: map.getCenter().lat(), lng: map.getCenter().lng()});

        if (shouldRefresh) refresh();
    }

    function openModal(p) {
        window.scrollTo({top: 0});
        setModelIsOpen(true);
        setPropertyForModal(p)
    }

    function closeModal(){
        setModelIsOpen(false);
    }

    function closeListingTypeFilterModal(){
        setListingTypeFilterOpen(false);
        refresh();
    }

    function closePriceFilterModal(){
        setPriceFilterOpen(false);
        refresh();
    }

    function closeHomeTypeFilterModal(){
        setHomeTypeFilterOpen(false);
        refresh();
    }

    function closeBedsBathsFilterModal(){
        setBedsBathsFilterOpen(false);
        refresh();
    }

    function startDrawing() {
        setDrawing(true);
        setPolygons([]);

        let mapDragListener = maps.event.addListener(map, 'mousedown', function (e) {
            let poly = new google.maps.Polyline({
                map: map,
                clickable: false,
                geodesic: true,
                strokeColor: "#88D203",
                fillColor: "rgba(136,210,3,0.5)",
                strokeWidth: 1,
            });

            let move = maps.event.addListener(map, 'mousemove', function (e) {
                poly.getPath().push(e.latLng);
            });

            maps.event.addListenerOnce(map, 'mouseup', function (e) {
                maps.event.removeListener(move);

                finishDrawing(poly);
            });
        });

        setMapDragListener(mapDragListener);
    }

    function cancelDrawing() {
        setDrawing(false);
        setPolygons([]);
        maps.event.removeListener(mapDragListener);
    }

    function finishDrawing(polyline) {
        const path = polyline.getPath().getArray();

        if (path) {
            dispatch(actions.property.searchPropertiesInsideArea(path.map(c => {return {latitude: c.lat(), longitude: c.lng()}})));
        }

        setPolygons(path ? [{coordinates: path, holes: []}] : []);
        setDrawing(false);

        // remove drag listener and delete polyline
        maps.event.removeListener(mapDragListener);
        polyline.setMap(null);
    }

    function renderPropertyCards() {
        let cards = [];



        for (let i = 0; i < Math.ceil(properties.length / 2); i+=2) {

          let row = [];
            row[0] = properties[i];
            if (properties[i + 1]) {
              row[1] = properties[i + 1];
            }
            cards.push(row);
        }

        return (
            <div>
            {
                cards.map(row =>
                    <div className='search-results-row'>
                        <PropertyCard key={row[0]._id} onClick={() => openModal(row[0])} property={row[0]}/>
                        { row[1] && <PropertyCard key={row[1]._id} onClick={() => openModal(row[1])} property={row[1]}/> }
                    </div>
                )
            }
            </div>
        )
    }

    function renderNoResults() {
        return (
            <div>
                <div className='search-results-title'>No Matching Results</div>
                <div className='search-results-subtitle'>Try Changing your search</div>
            </div>
        )
    }

    return (
        <div className='search-container'>

            <PropertyModal isOpen={modalIsOpen} onRequestClose={closeModal} property={propertyForModal}/>
            <ListingTypeFilterModal isOpen={listingTypeFilterOpen} onRequestClose={closeListingTypeFilterModal}/>
            <PriceFilterModal isOpen={priceFilterOpen} onRequestClose={closePriceFilterModal}/>
            <HomeTypeFilterModal isOpen={homeTypeFilterOpen} onRequestClose={closeHomeTypeFilterModal}/>
            <BedsBathsFilterModal isOpen={bedsBathsFilterOpen} onRequestClose={closeBedsBathsFilterModal}/>

            <div className='search-mobile-controls'>
                <div className='search-mobile-controls-item' onClick={() => setShowMap(true)}>
                    <FaMap/>
                    Mapa
                </div>
                <div className='search-mobile-controls-item' onClick={() => setShowMap(false)}>
                    <FaList/>
                    Lista
                </div>
            </div>

            <div className='search-top-bar'>
                <div className='search-top-bar-row'>
                    <SearchBar
                        containerStyle={{height: 'fit-content', marginRight: 8}}
                        inputStyle={{padding: 0, margin: 8}}
                        onSubmit={address => getLocation(address)}
                        defaultValue={query.get('address')? decodeURIComponent(query.get('address')) : null}
                    />

                    <FilterButton
                        label={listingTypeFilter?.map(t => {
                            if (t === 'rent') return 'Alquilar';
                            if (t === 'sale') return 'En Venta';
                            else return '';
                        }).join(', ')}
                        onClick={() => setListingTypeFilterOpen(true)}
                    />
                    <FilterButton
                        label={
                            !priceMinFilter && !priceMaxFilter ?
                                'Precio'
                                :
                                (priceMinFilter? '$' + priceMinFilter : 0) + (priceMaxFilter? ' - $' + priceMaxFilter : '+')
                        }
                        onClick={() => setPriceFilterOpen(true)}
                    />
                    <FilterButton
                        label={!bedsFilter && !bathsFilter ?
                            'Camas y baños'
                            :
                            ((bedsFilter? bedsFilter : 0) + '+ bds, ' + (bathsFilter? bathsFilter : 0) + '+ bths')
                        }
                        onClick={() => setBedsBathsFilterOpen(true)}
                    />
                    <FilterButton
                        label={homeTypeFilter && homeTypeFilter?.length ?
                            homeTypeFilter.map(t => {
                                if (t === 'home') return 'Casas';
                                if (t === 'apartment') return 'Apartamentos';
                                //if (t === 'terracedhouse') return 'Casas Adosadas';
                                if (t === 'land') return 'Lotes / Terrenos';
                                //if (t === 'condominium') return 'Condominio';
                                //if (t === 'prefabricated') return 'Fabricado';
                                if (t === 'farm') return 'Fincas';
                                if (t === 'commercial') return 'Local Comercial';
                                if (t === 'other') return 'Otro';
                                else return t;
                            }).join(', ')
                            :
                            'Tipo de casa'
                        }
                        onClick={() => setHomeTypeFilterOpen(true)}
                    />
                </div>
            </div>

            <div className='search-content'>
                <div className='search-map' style={{display: (!isLaptop && showMap) || isLaptop ? 'block' : 'none'}}>
                    <div className='search-draw-button'>
                        {
                            drawing?
                                <>
                                    {/* not used now with drag */}
                                    {/*<PrimaryButton
                                        icon={<FaPencilAlt/>}
                                        label={'Confirmar'}
                                        onClick={() => {
                                            finishDrawing()
                                        }}
                                    />

                                    <PrimaryButton
                                        icon={<FaPencilAlt/>}
                                        label={'Cancelar'}
                                        onClick={() => {
                                            cancelDrawing();
                                            refresh();
                                        }}
                                    />*/}
                                </>
                                :
                                <>
                                    <PrimaryButton
                                        icon={<FaPencilAlt/>}
                                        label={'Dibujar'}
                                        onClick={() => {
                                            startDrawing()
                                        }}
                                    />
                                    {
                                        !!polygons && !!polygons.length &&
                                        <PrimaryButton
                                            icon={<FaEraser/>}
                                            label={'Borrar Dibujo'}
                                            onClick={() => {
                                                cancelDrawing();
                                                refresh(false);
                                            }}
                                        />
                                    }
                                </>
                        }
                    </div>
                    <div className='search-show-prices-button'>
                        <SecondaryButton
                            icon={<FaPencilAlt/>}
                            label={markerType === markerTypes.PRICE_MARKER? 'Esconder precios' : 'Mostrar precios'}
                            onClick={() => {
                                markerType === markerTypes.PRICE_MARKER ? setMarkerType(markerTypes.LISTING_TYPE_MARKER) : setMarkerType(markerTypes.PRICE_MARKER)
                            }}
                        />
                    </div>

                    <div className='search-map-subtitle-container'>
                        <small>Leyenda:</small>

                        <div className='search-map-subtitle-item'>
                            <img src={'/sale-marker.png'} style={{width: 39, height: 39}} alt={'sale'}/>
                            <div>En venta</div>
                        </div>

                        <div className='search-map-subtitle-item'>
                            <img src={'/rent-marker.png'} style={{width: 39, height: 39}} alt={'rent'}/>
                            <div>Para alquilar</div>
                        </div>
                    </div>

                   {/* <Map
                        onMarkerClick={openModal}
                        onDragEnd={refresh}
                        onCenterChanged={onCenterChanged}
                        markers={properties}
                        markerType={markerType}
                        ref={mapRef}
                        center={mapCenter}
                        onClick={
                            drawing?
                                e => onMapDrag(e)
                                :
                                () => {
                                    cancelDrawing(() => refresh());
                                }
                        }
                        onDrag={drawing? e => onMapDrag(e) : () => {}}
                        polygons={polygons}
                        polylines={editing}
                    />*/}

                    <SimpleMap
                        onMarkerClick={openModal}
                        onDragEnd={!drawing? () => refresh() : () => {}}
                        onCenterChanged={onCenterChanged}
                        markers={properties}
                        markerType={markerType}
                        ref={mapRef}
                        center={mapCenter}
                        zoom={6}
                        onClick={() => {}}
                        polygons={polygons}
                        handleApiLoaded={handleMapsApiLoaded}
                        draggable={!drawing}
                    />
                </div>

                <div className='search-results-container' style={{display: (!isLaptop && !showMap) || isLaptop ? 'block' : 'none'}}>
                    <div className='search-spinner-wrapper'>
                        <FadeLoader
                            color={'#88D203'}
                            loading={fetchingPropertiesInArea}
                        />
                        {fetchingPropertiesInArea && <span className='search-spinner-text'>Buscando propiedades...</span>}
                    </div>

                    {!fetchingPropertiesInArea?
                        (properties && properties.length > 0 ?
                            renderPropertyCards()
                            :
                            renderNoResults())
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default Search;
