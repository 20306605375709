import React from 'react';
import './SmallLabel.css';

function SmallLabel({label, green=true}) {
    return (
        <div className={green? 'smalllabel-container-green' : 'smalllabel-container-grey'} style={{marginBottom: 6}}>
            <div className={green? 'smalllabel-label-green' : 'smalllabel-label-grey'}>
                {label}
            </div>
        </div>
    );
}

export default SmallLabel;
