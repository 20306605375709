import React from 'react';
import './HomePage.css';
import SearchBar from "../../components/SearchBar/SearchBar";
import { useHistory } from "react-router-dom";
import Search from "../Search/Search";

function HomePage() {
    const history = useHistory();

    return (
        <div>
            {/*<div className="App">

                <div className="App-header">

                    <div className='App-header-container'>
                        <p className='App-header-title'>
                            Home has never been more important
                        </p>

                        <SearchBar
                            containerStyle={{height: 'fit-content', marginRight: 8, width: '50vw'}}
                            onSubmit={address => history.push("/search?address=" + address)}
                        />
                    </div>

                    <div className='App-header-background'></div>

                </div>
            </div>*/}


            <Search/>
        </div>


    );
}

export default HomePage;
