import {store} from '../index';
import {userActionTypes} from "../store/modules/user/constants";
import AsyncStorage from '@callstack/async-storage';
import { useHistory } from "react-router-dom";
const axios = require('axios').default;

// Set config defaults when creating the instance
const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL ? 
        process.env.REACT_APP_BACKEND_URL
        : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'https://loro-dev.herokuapp.com/' : "https://loro-prod.herokuapp.com/",
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

// Invoked after before every request
instance.interceptors.request.use(async function (config) {
    const token = await AsyncStorage.getItem("token");

    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



// Invoked after every response
instance.interceptors.response.use(function (response) {

    return response;
}, async function (error) {

    const token = await AsyncStorage.getItem("token");

    const originalRequest = error.response.data;

    if (error.response.status === 403 && !originalRequest.validToken) {

        originalRequest.validToken = true;

        //https://stackoverflow.com/questions/51439338/abort-all-axios-requests-when-change-route-use-vue-router
        //if we are to abort all of them, check link. We need to go to each service and put the token there
        //instance.CancelToken.source().cancel('Abort all operations, we\'re logging out');

        store.dispatch({type: userActionTypes.LOGOUT});
        await AsyncStorage.removeItem("token");
        const history = useHistory();
        history.push("/login");

        /*
        //renews token
        await instance.post('users/token', {
            refreshToken: re)freshToken
        })
            .then(async function (response) {
                    console.log(response);
                    await AsyncStorage.setItem("token", response.data.token);
                    return axios(originalRequest);
                },
                (error) => {
                    console.log("renew deu crash");
                    NavigationService.navigate('SignIn');
                },
                )
                */
    }
    return Promise.reject(error.response);
});

export const http = instance;
