import {userActionTypes} from './constants';
import {userService} from "../../services/userService";
import FirebaseService from "../../../utils/FirebaseService";

export const login = (email, password) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.LOGIN_REQUEST,
        });

        return userService.login(email, password).then(
            res => {
                dispatch({
                    type: userActionTypes.LOGIN_SUCCESS,
                    payload: { token: res.data.token }
                });
                return res.data;
            },
            error => {
                dispatch({
                    type: userActionTypes.LOGIN_FAILURE,
                    payload: {
                        error: "E-mail/password combination does not exist."
                    }
                })
                return error;
            }
        );
    }
};

export const loginToken = (token) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.LOGIN_SUCCESS,
            payload: { token: token }
        });
    }
};

export const renewToken = () => {
    return function (dispatch) {
        return userService.renewToken().then(
            res => {
                dispatch({
                    type: userActionTypes.LOGIN_SUCCESS,
                    payload: { token: res.data.token }
                });
                return res.data;
            },
            error => {
                dispatch({
                    type: userActionTypes.LOGIN_FAILURE,
                    payload: {
                        error: "E-mail/password combination does not exist."
                    }
                })
                return error;
            }
        );
    }
};

export const logout = () => {
    return function (dispatch) {

        dispatch({
            type: userActionTypes.LOGOUT,
        });

        return userService.logout();
    }
};

export const register = (username, email, phone, password, confirmationCode) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.REGISTER_REQUEST
        });

        return userService.register(username, email, phone, password, confirmationCode).then(
            res => {
                dispatch({
                    type: userActionTypes.REGISTER_SUCCESS,
                    payload: { token: res.data.token }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.REGISTER_FAILURE,
                    payload: {
                        error: error.data.error
                    }
                })
                return error;
            }
        );
    }
};

export const updateUser = (image, username, bio, email, phone, hidePhone, address, city, state) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.UPDATE_USER_REQUEST
        });

        function updateUserCallback(pictureUrl, username, bio, email, phone, hidePhone, address, city, state) {
            return userService.updateUser(pictureUrl, username, bio, email, phone, hidePhone, address, city, state).then(
                res => {
                    dispatch({
                        type: userActionTypes.UPDATE_USER_SUCCESS,
                        payload: { user: res }
                    });
                    return res;
                },
                error => {
                    dispatch({
                        type: userActionTypes.UPDATE_USER_FAILURE,
    
                    })
                    return error;
                }
            );
        }

        if (image?.data_url) {
            return FirebaseService.shared.uploadUserPicture(image).then(pictureUrl => {
                return updateUserCallback(pictureUrl, username, bio, email, phone, hidePhone, address, city, state);
            });
        } else {
            return updateUserCallback(null, username, bio, email, phone, hidePhone, address, city, state);
        }
    }
};

export const requestConfirmationCode = (phone, email, useEmail) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.OTP_REQUEST
        });

        return userService.requestConfirmationCode(phone, email, useEmail).then(
            res => {
                dispatch({
                    type: userActionTypes.OTP_SUCCESS,
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.OTP_FAILURE,
                    payload: {
                        error: "Error al solicitar un código de confirmación."
                    }
                })
                return error;
            }
        );
    }
};

export const checkIfEmailIsAvailable = (email) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.CHECK_EMAIL_AVAILABLE_REQUEST
        });

        return userService.checkIfEmailIsAvailable(email).then(
            res => {
                dispatch({
                    type: userActionTypes.CHECK_EMAIL_AVAILABLE_SUCCESS,
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.CHECK_EMAIL_AVAILABLE_FAILURE,
                    payload: {
                        error: 'Correo electrónico ya está en uso'
                    }
                })
                return error;
            }
        );
    }
};

export const changePassword = (email, password, confirmationCode) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.CHANGE_PASSWORD_REQUEST
        });

        return userService.changePassword(email, password, confirmationCode).then(
            res => {
                dispatch({
                    type: userActionTypes.CHANGE_PASSWORD_SUCCESS,
                    payload: { }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.CHANGE_PASSWORD_FAILURE,
                    payload: {
                        error: "Failed to change password."
                    }
                })
                return error;
            }
        );
    }
};

export const fetchUser = () => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.FETCH_USER_REQUEST
        });

        return userService.userDetails().then(
            res => {
                dispatch({
                    type: userActionTypes.FETCH_USER_SUCCESS,
                    payload: {
                        user: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.FETCH_USER_FAILURE,
                    payload: {
                        user: null
                    }
                });
                return error;
            }
        );
    }
};

export const fetchUserById = (userId) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.FETCH_USER_BY_ID_REQUEST
        });

        return userService.anotherUserDetails(userId).then(
            res => {
                dispatch({
                    type: userActionTypes.FETCH_USER_BY_ID_SUCCESS,
                    payload: {
                        user: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.FETCH_USER_BY_ID_FAILURE,
                    payload: {
                        user: null
                    }
                });
                return error;
            }
        );
    }
};

export const fetchMyListings = () => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.FETCH_MY_LISTINGS_REQUEST
        });

        return userService.getMyListings().then(
            res => {
                dispatch({
                    type: userActionTypes.FETCH_MY_LISTINGS_SUCCESS,
                    payload: {
                        listings: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.FETCH_MY_LISTINGS_FAILURE,
                    payload: {
                        listings: []
                    }
                });
                return error;
            }
        );
    }
};


export const fetchFavorites = () => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.FETCH_FAVORITES_REQUEST
        });

        return userService.getFavoriteProperties().then(
            res => {
                dispatch({
                    type: userActionTypes.FETCH_FAVORITES_SUCCESS,
                    payload: {
                        favorites: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.FETCH_FAVORITES_FAILURE,
                    payload: {
                        favorites: []
                    }
                });
                return error;
            }
        );
    }
};

export const addFavorite = (propertyId) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.ADD_FAVORITE_REQUEST
        });

        return userService.addPropertyToFavorites(propertyId).then(
            res => {
                dispatch({
                    type: userActionTypes.ADD_FAVORITE_SUCCESS,
                    payload: {
                    }
                });

                dispatch(fetchFavorites());

                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.ADD_FAVORITE_FAILURE,
                    payload: {
                    }
                });
                return error;
            },
        );
    }
};


export const removeFavorite = (propertyId) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.ADD_FAVORITE_REQUEST
        });

        return userService.removePropertyFromFavorites(propertyId).then(
            res => {
                dispatch({
                    type: userActionTypes.ADD_FAVORITE_SUCCESS,
                    payload: {
                    }
                });

                dispatch(fetchFavorites());

                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.ADD_FAVORITE_FAILURE,
                    payload: {
                    }
                });
                return error;
            },
        );
    }
};

export const deleteAccount = (otp) => {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.DELETE_ACCOUNT_REQUEST
        });

        return userService.deleteAccount(otp).then(
            res => {
                dispatch({
                    type: userActionTypes.DELETE_ACCOUNT_SUCCESS,
                    payload: {
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: userActionTypes.DELETE_ACCOUNT_FAILURE,
                    payload: {
                    }
                });
                throw error;
            }
        );
    }
};