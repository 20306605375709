import React, {useEffect, useState} from 'react';
import './AgentProfile.css';
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/modules";
import {useParams} from "react-router-dom";
import UserContact from "../../components/UserContact/UserContact";
import {markerTypes} from "../../components/Map/Map";
import PropertyHorizontalCard from "../../components/PropertyHorizontalCard/PropertyHorizontalCard";
import SendMessage from "../../components/SendMessage/SendMessage";
import { useHistory } from "react-router-dom";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import SimpleMap from "../../components/Map/ReactMap";

const LATITUDE = 4.60971;
const LONGITUDE = -74.08175;


function AgentProfile() {
    let { id } = useParams();

    const agent = useSelector(state => state.user.otherUser);
    const properties = useSelector(state => state.property.propertiesFromAgent);
    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(actions.property.fetchPropertiesFromAgent(id));
        dispatch(actions.user.fetchUserById(id));
    }, []);

    const [mapRegion, setMapRegion] = useState({
        lat: LATITUDE,
        lng: LONGITUDE,
    });

    useEffect(() => {
        if (properties && properties.length > 0) {
            let sumLat = 0;
            let sumLong = 0;
            let minLat = null;
            let minLong = null;
            let maxLat = null;
            let maxLong = null;
            for (const p of properties) {
                sumLat += p.latitude;
                sumLong += p.longitude;

                if (!minLat || minLat > p.latitude) {
                    minLat = p.latitude;
                }
                if (!minLong || minLong > p.longitude) {
                    minLong = p.longitude;
                }
                if (!maxLat || maxLat < p.latitude) {
                    maxLat = p.latitude;
                }
                if (!maxLong || maxLong < p.longitude) {
                    maxLong = p.longitude;
                }
            }

            setMapRegion({
                lat: sumLat / properties.length,
                lng: sumLong / properties.length,
            });
        }
    }, [properties]);

    return (
        <div className="agentprofile-container">

            <div className="agentprofile-header">
            </div>

            <div className="agentprofile-cols">

                <div className="agentprofile-content">
                    <UserContact user={agent}/>

                    <div className='agentprofile-bio'>{agent?.bio}</div>

                    {
                        properties && properties.length > 0 &&
                        <>
                            {
                                properties.filter(p => p.status === "active").length > 0 &&
                                <>
                                    <div className='agentprofile-map'>
                                        <SimpleMap
                                            zoom={5}
                                            markers={properties.filter(p => p.status === "active")}
                                            markerType={markerTypes.LISTING_TYPE_MARKER}
                                        />
                                    </div>

                                    <div className='agentprofile-title'>
                                        Listados Activos
                                    </div>
                                    <div className='agentprofile-label-container'>
                                        <div className='agentprofile-label-text'>
                                            Descripción de la Propiedad
                                        </div>
                                        <div className='agentprofile-label-text'>
                                            Precio
                                        </div>
                                    </div>
                                    {
                                        properties && properties.length > 0 &&
                                        properties.filter(p => p.status === "active").map(p =>
                                            <PropertyHorizontalCard property={p} onClick={() => history.push("/search?property=" + p._id)}/>
                                        )
                                    }
                                </>
                            }

                            {
                                properties.filter(p => p.status === "sold").length > 0 &&
                                <>

                                    <div className='agentprofile-title'>
                                        Ventas Passada
                                    </div>
                                    <div className='agentprofile-label-container'>
                                        <div className='agentprofile-label-text'>
                                            Descripción de la Propiedad
                                        </div>
                                        <div className='agentprofile-label-text'>
                                            Precio
                                        </div>
                                    </div>
                                    {
                                        properties && properties.length > 0 &&
                                        properties.filter(p => p.status === "sold").map(p =>
                                            <PropertyHorizontalCard property={p} onClick={() => history.push("/search?property=" + p._id)}/>
                                        )
                                    }
                                </>
                            }

                            {
                                agent?.ratings?.length > 0 &&
                                <>

                                    <div className='agentprofile-title'>
                                        Calificaciones y Comentarios
                                    </div>

                                    {
                                        agent.ratings &&  agent.ratings.length > 0 &&
                                        agent.ratings.map(p =>
                                            <ReviewCard review={p} onClick={() => {}}/>
                                        )
                                    }
                                </>
                            }
                        </>
                    }
                </div>

               {/*  
               <div className="agentprofile-message-col">
                    <SendMessage user={agent}/>
                </div> 
                */}
            </div>
        </div>
    );
}

export default AgentProfile;
