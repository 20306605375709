import React from 'react';
import './AccountSettings.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {actions} from "../../store/modules";
import Input from "../../components/Input/Input";
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ImageUploading from 'react-images-uploading';
import SmallButton from '../../components/SmallButton/SmallButton';
import { FadeLoader } from 'react-spinners';


class AccountSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pictures: [], //array of pictures uploaded. Only one picture is allowed
            name: null,
            bio: null,
            phone: null,
            hidePhone: false,
            email: null,
            address: null,
            state: null,
            city: null,
        }

        this._submit = this._submit.bind(this);
        this.onImageUpload = this.onImageUpload.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        this.props.fetchUser().then(user => {
            this.setState({
                pictures: user?.picture ? [user?.picture] : ['default-user-2.png'],
                name: user.username,
                bio: user.bio,
                phone: user.phone,
                hidePhone: user.hidePhone,
                email: user.email,
                address: null,
                state: null,
                city: null,
            })
        }
        )
    }

    onImageUpload(imageList, addUpdateIndex) {
        this.setState({pictures: [imageList[addUpdateIndex]]});
    };

    onError(error){
        alert('Error al cargar la nueva imagen');
        console.error(error);
        this.setState({pictures: ['./default-user-2.png']});
    }

    render() {
        const {pictures, name, bio, phone, hidePhone, email, address, state, city} = this.state;
        const {updatingUser} = this.props;

        const singlePicture = pictures.length > 0 ? pictures[0] : null; 
        const imgData = singlePicture?.data ? 'data:' + singlePicture.mime + ';base64,' + singlePicture.data :
            singlePicture?.data_url ? singlePicture.data_url : singlePicture ? singlePicture : './default-user-2.png';

        return (
            <div className='accountsettings-container'>
                <div className='accountsettings-scrollView'>
                    <div className='accountsettings-inputLabel'>{'Foto de perfil'}</div>
                    <div className='accountsettings-inputContainer'>
                        <img src={imgData}
                            className='accountsettings-image'
                            onError={() => this.setState({pictures: ['./default-user-2.png']})}
                        />
                        <ImageUploading
                            value={pictures}
                            onChange={this.onImageUpload}
                            onError={this.onError}
                            maxNumber={3}
                            dataURLKey="data_url">
                            {({
                                onImageUpload,
                                onImageRemoveAll,
                            }) => (
                                <SmallButton
                                    label={'Cambiar foto'}
                                    onClick={() => {
                                        onImageRemoveAll();
                                        onImageUpload();
                                    }}
                                />
                            )}
                        </ImageUploading>
                    </div>


                    <div className='accountsettings-inputLabel'>{'Nombre'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={''}
                            value={name}
                            onChange={(name) => this.setState({name})}/>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Biografía'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={''}
                            value={bio}
                            onChange={(bio) => this.setState({bio})}/>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Número de teléfono'}</div>
                    <div className='accountsettings-inputContainer'>
                        <PhoneInput 
                            className='input-el' 
                            countryCallingCodeEditable={false} 
                            international 
                            defaultCountry='CO'
                            value={phone} 
                            onChange={(phone) => this.setState({phone})} 
                        />
                        {
                            phone?.length > 0 && !isValidPhoneNumber(phone) &&
                            <div className='input-error'>
                                Ingrese un número de teléfono válido
                            </div>
                        }
                        <input 
                            style={{marginTop: 10}}
                            id='hidePhone'
                            checked={hidePhone} 
                            type='checkbox' 
                            onChange={() => this.setState({hidePhone: !hidePhone})}
                            />
                        <label for='hidePhone' className='accountsettings-inputLabel'>
                            {' Ocultar mi número de teléfono en el perfil'}
                        </label>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Email'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={'example@example.com'}
                            value={email}
                            onChange={(email) => this.setState({email})}/>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Dirección'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={''}
                            value={address}
                            onChange={(address) => this.setState({address})}/>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Ciudad'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={''}
                            value={city}
                            onChange={(city) => this.setState({city})}/>
                    </div>

                    <div className='accountsettings-inputLabel'>{'Departamento'}</div>
                    <div className='accountsettings-inputContainer'>
                        <Input
                            placeholder={''}
                            value={state}
                            onChange={(state) => this.setState({state})}/>
                    </div>

                    <PrimaryButton label={'Guardar'} onClick={this._submit} disabble={updatingUser}/>
                    <FadeLoader
                        color={'#88D203'}
                        loading={updatingUser}
                    />

                </div>
            </div>
        );
    }

    _submit() {
        const singlePicture = this.state.pictures.length > 0 ? this.state.pictures[0] : null; 

        this.props.updateUser(singlePicture, this.state.name, this.state.bio, this.state.email, this.state.phone, this.state.hidePhone, this.state.address, this.state.city, this.state.state)
            .then(() => {
                this.props.history.push("/profile");
            })
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        updatingUser: state.user.updatingUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: () =>
            dispatch(actions.user.fetchUser()),
        updateUser: (picture, username, bio, email, phone, hidePhone, address, city, state) =>
            dispatch(actions.user.updateUser(picture, username, bio, email, phone, hidePhone, address, city, state)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));
