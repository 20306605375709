import {http} from "../../utils/http";

export const ratingService = {
    getAgentRatings,
    createRating,
    fetchOwnRating,
    fetchAgentAverage,
};

function getAgentRatings(agentId) {

    return http.get('ratings/' + agentId + '?withoutSelf=true')
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

function fetchAgentAverage(agentId) {
    return http.get('ratings/' + agentId + '/average')
        .then(function (response) {
            return response.data.ratingAverage;
        })
        .catch(function (error) {
            throw error;
        });
}

function createRating(comment, rating, agentId) {

    return http.post('ratings/' + agentId, {
        comment: comment,
        value: rating,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

function fetchOwnRating(agentId) {

    return http.get('ratings/' + agentId + '/own')
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

