import React, { useState, useEffect } from 'react';
import '../Login/Login.css';
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";
import Input from "../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import OtpInput from 'react-otp-input';


function Register() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.registerLoading);
    const registerFailed = useSelector(state => state.user.registerFailed);
    const loggedIn = useSelector(state => state.user.loggedIn);
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [otp, setOtp] = useState(null);
    const [step, setStep] = useState(0);
    const history = useHistory();



    useEffect(function () {
        if (loggedIn) {
            history.push("/");
        }
    }, []);

    useEffect(function () {
        if (loggedIn) {
            history.push("/");
        }
    }, [loading]);

    function _nextStep() {
        if (step === 0) {
            if (username?.length >= 3 && password?.length >= 6 && validateEmail(email) && isValidPhoneNumber(phone)) {
                dispatch(actions.user.checkIfEmailIsAvailable(email)).then(res => {
                    if (res === 200) {
                        dispatch(actions.user.requestConfirmationCode(phone, email, false)).then(res => {
                            if (res.status === 200) {
                                setStep(step + 1);
                            }
                        });
                    }
                });
            } else {
                return alert('Por favor, complete todos los campos y corrija los errores');
            }
        } else if (step === 1) {
            submit();
        }
    }

    function submit() {
        dispatch(actions.user.register(username, email, phone, password, otp));
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <form className='login-container'>

            <Link to="/login">
                <img src={'logo-wo-bg.png'} className="login-logo" alt="logo" />
            </Link>

            <div className='login-form-content'>
                <div className='login-title'>
                    ¡Bienvenido!
                </div>

                <div className='login-subtitle'>
                    { step === 0 ? 'Ingrese sus dados' : 'Ingrese el código PIN que recibió por WhatsApp' }
                </div>

                {
                    step === 0 &&
                    <>
                        <Input placeholder={'Nombre completo'} type={'text'} label='Nombre' onChange={setUsername} id={'name'} errorMessage={username?.length > 0 && username?.length < 3 && 'Tu nombre es demasiado corto'} />
                        <Input placeholder={'example@example.com'} type={'email'} label='E-mail' onChange={setEmail} id={'email'} errorMessage={email?.length > 0 && !validateEmail(email) && 'Ingrese un correo electrónico válido'} />
                        <div className='input-container'>
                            <div className='input-label'>
                                Número de teléfono móvil
                            </div>
                            <PhoneInput className='input-el' countryCallingCodeEditable={false} international defaultCountry='CO' value={phone} onChange={setPhone} />
                            {
                                phone?.length > 0 && !isValidPhoneNumber(phone) &&
                                <div className='input-error'>
                                    Ingrese un número de teléfono válido
                                </div>
                            }
                        </div>
                        <Input placeholder={'● ● ● ● ● ●'} type={'password'} label='contraseña' onChange={setPassword} id={'password'} errorMessage={password?.length > 0 && password?.length < 6 && 'Tu contraseña es demasiado corta'} />
                    </>
                }
                {
                    step === 1 &&
                    <>
                        <OtpInput value={otp} onChange={setOtp} numInputs={4} placeholder={'0000'} inputStyle={'input-el input-el-otp'} isInputNum={true} />
                    </>
                }

                <FadeLoader
                    color={'#88D203'}
                    loading={loading}
                />
            </div>

            {
                registerFailed &&
                <div className='login-error'>
                    {registerFailed}
                </div>
            }

            {
                step === 0 &&
                <>
                    <PrimaryButton label='Próximo paso' onClick={_nextStep} />
                    <p>o</p>
                    <Link to='/login'>
                        <SecondaryButton label='Iniciar Sesión' />
                    </Link>
                </>
            }
            {
                step === 1 &&
                <>
                    <PrimaryButton label='Confirmar' onClick={submit} />
                    <br/>
                    <SecondaryButton label={'Enviar al correo electrónico'} 
                        onClick={() => dispatch(actions.user.requestConfirmationCode(null, email, true))}/>
                    <br/>
                    <div className='login-tip'>
                        No recibiste un mensaje de WhatsApp con el código de verificación?<br/>
                        Intenta recibirlo en tu correo electrónico
                    </div>
                </>
            }

        </form>
    );
}

export default Register;
