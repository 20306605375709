import React, {useEffect, useState} from 'react';
import './ChatPreview.css';
import {useDispatch} from "react-redux";
import {actions} from "../../store/modules";
import TimeAgo from 'javascript-time-ago';
import es from "javascript-time-ago/locale/es";
import {useHistory} from 'react-router-dom';

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function ChatPreview({userId, chatId, lastMessage, unseenCount, query = ''}){
    const history = useHistory();
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();

    const [image, setImage] = React.useState({ uri: user?.picture });

    function onError(error){
        setImage('./default-user-2.png');
    }

    useEffect(() => {
        dispatch(actions.user.fetchUserById(userId)).then(u => {
            setUser(u)
            setImage({ uri: u.picture })
        })
    }, [userId]);

    function cutText(text) {
        if (text.length > 35) return text.slice(0, 34) + '...';
        return text;
    }

    return (
        user && user.username?.toLowerCase().startsWith(query.toLowerCase())?
            <div  className='chatpreview-container' onClick={() => history.push({pathname: '/chat', state: {chatId: chatId, userId: userId}})}>
                <img src={image} className='chatpreview-image' onError={onError}
                />

                <div className='chatpreview-contentView'>
                    <div className='chatpreview-nameRow'>
                        <div className='chatpreview-nameText'>{user.username}</div>
                        <div className='chatpreview-timeText'>{lastMessage?.timestamp ? timeAgo.format(Date.parse(lastMessage.timestamp)) : ''}</div>
                    </div>

                    <div className='chatpreview-nameRow'>
                        <div className='chatpreview-messageText'>{lastMessage?.text ? (lastMessage.user.id !== userId? 'Tú: ' : '').concat(cutText(lastMessage.text)) : ''}</div>
                        {
                            unseenCount > 0 &&
                            <div className='chatpreview-badge'>
                                <div style={{ color: 'white', textAlign: 'center' }}>{unseenCount}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            :
            <></>
    );
}

export default ChatPreview;
