import {app} from './app'
import {property} from './property'
import {user} from './user'
import {chat} from './chat'
import {agent} from "./agent";
import {rating} from "./rating";

/**
 * Root reducers.
 */
export const reducers = {
    app: app.reducer,
    property: property.reducer,
    user: user.reducer,
    chat: chat.reducer,
    agent: agent.reducer,
    rating: rating.reducer,
};

/**
 * Root actions.
 */
export const actions = {
    app: app.actions,
    property: property.actions,
    user: user.actions,
    chat: chat.actions,
    agent: agent.actions,
    rating: rating.actions,
};

export { app, property, user, rating, agent, chat }
