import React from 'react';
import './BedsBathsFilterModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import SmallButton from "../SmallButton/SmallButton";

Modal.setAppElement(document.getElementById('root'));

function BedsBathsFilterModal({isOpen, onRequestClose}) {

    const dispatch = useDispatch();
    const bedsFilter = useSelector(state => state.property.bedsFilter);
    const bathsFilter = useSelector(state => state.property.bathsFilter);


    const homeTypeFilter = useSelector(state => state.property.homeTypeFilter);
    const listingTypeFilter = useSelector(state => state.property.listingTypeFilter);
    const priceMinFilterRent = useSelector(state => state.property.priceMinFilterRent);
    const priceMaxFilterRent = useSelector(state => state.property.priceMaxFilterRent);
    const priceMinFilterSale = useSelector(state => state.property.priceMinFilterSale);
    const priceMaxFilterSale = useSelector(state => state.property.priceMaxFilterSale);

    function changeBedsFilter(beds) {
        dispatch(actions.property.changeFilters(listingTypeFilter, priceMinFilterRent, priceMaxFilterRent,priceMinFilterSale, priceMaxFilterSale, homeTypeFilter, beds, bathsFilter))
    }

    function changeBathsFilter(baths) {
        dispatch(actions.property.changeFilters(listingTypeFilter, priceMinFilterRent, priceMaxFilterRent,priceMinFilterSale, priceMaxFilterSale, homeTypeFilter, bedsFilter, baths))
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Listing Type Filter'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='bedsbathsfiltermodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <div className='bedsbathsfiltermodal-content'>
                <button onClick={onRequestClose} className='bedsbathsfiltermodal-close-btn'>
                    <GrClose/>
                </button>

                <div className='bedsbathsfiltermodal-options-title'>
                    Habitaciones
                </div>
                <div className='bedsbathsfiltermodal-options'>
                    <SmallButton label={'Any'} green={!bedsFilter || bedsFilter == 0} onClick={() => changeBedsFilter(0)}/>
                    <SmallButton label={'1+'} green={bedsFilter == 1} onClick={() => changeBedsFilter(1)}/>
                    <SmallButton label={'2+'} green={bedsFilter == 2} onClick={() => changeBedsFilter(2)}/>
                    <SmallButton label={'3+'} green={bedsFilter == 3} onClick={() => changeBedsFilter(3)}/>
                    <SmallButton label={'4+'} green={bedsFilter == 4} onClick={() => changeBedsFilter(4)}/>
                    <SmallButton label={'5+'} green={bedsFilter == 5} onClick={() => changeBedsFilter(5)}/>
                </div>

                <div className='bedsbathsfiltermodal-options-title'>
                    Baños
                </div>
                <div className='bedsbathsfiltermodal-options'>
                    <SmallButton label={'Any'} green={!bathsFilter || bathsFilter == 0} onClick={() => changeBathsFilter(0)}/>
                    <SmallButton label={'1+'} green={bathsFilter == 1} onClick={() => changeBathsFilter(1)}/>
                    <SmallButton label={'2+'} green={bathsFilter == 2} onClick={() => changeBathsFilter(2)}/>
                    <SmallButton label={'3+'} green={bathsFilter == 3} onClick={() => changeBathsFilter(3)}/>
                    <SmallButton label={'4+'} green={bathsFilter == 4} onClick={() => changeBathsFilter(4)}/>
                    <SmallButton label={'5+'} green={bathsFilter == 5} onClick={() => changeBathsFilter(5)}/>
                </div>

                <PrimaryButton label={'Aplicar'} onClick={onRequestClose}/>
            </div>
        </Modal>
    );
}

export default BedsBathsFilterModal;
