import {handleActions} from 'redux-actions';
import {userActionTypes} from "./constants";

const initialState = {
    favorites: [],
    fetchingFavorites: false,

    listings: [],
    fetchingListings: false,

    loggedIn: false,
    loginLoading: false,
    loginFailed: null,

    registerLoading: false,
    registerFailed: null,

    updatingUser: false,
    fetchingUser: false,
    user: null,

    otherUser: null,
    fetchingOtherUser: false
};

/**
 * Action handler
 */
export default handleActions(
    {
        [userActionTypes.LOGIN_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                loginFailed: null,
                loginLoading: true,
            }
        },
        [userActionTypes.LOGIN_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                loggedIn: false,
                loginFailed: payload.error,
                loginLoading: false,
            }
        },
        [userActionTypes.LOGIN_SUCCESS]: (state = initialState, action) => {
            return {
                ...state,
                loggedIn: true,
                loginFailed: null,
                loginLoading: false,
            }
        },
        [userActionTypes.REGISTER_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                registerFailed: null,
                registerLoading: true,
            }
        },
        [userActionTypes.REGISTER_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                loggedIn: false,
                registerFailed: payload.error,
                registerLoading: false,
            }
        },
        [userActionTypes.REGISTER_SUCCESS]: (state = initialState, action) => {
            return {
                ...state,
                loggedIn: true,
                registerFailed: null,
                registerLoading: false,
            }
        },
        [userActionTypes.CHECK_EMAIL_AVAILABLE_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                registerFailed: null,
            }
        },
        [userActionTypes.CHECK_EMAIL_AVAILABLE_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                registerFailed: payload.error,
            }
        },
        [userActionTypes.LOGOUT]: (state = initialState, action) => {
            return {
                ...state,
                loggedIn: false
            }
        },

        [userActionTypes.FETCH_FAVORITES_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingFavorites: true
            }
        },
        [userActionTypes.FETCH_FAVORITES_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingFavorites: false,
                favorites: payload.favorites,
            }
        },
        [userActionTypes.FETCH_FAVORITES_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                favorites: [],
                fetchingFavorites: false
            }
        },

        [userActionTypes.FETCH_MY_LISTINGS_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingListings: true
            }
        },
        [userActionTypes.FETCH_MY_LISTINGS_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingListings: false,
                listings: payload.listings,
            }
        },
        [userActionTypes.FETCH_MY_LISTINGS_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                listings: [],
                fetchingListings: false
            }
        },

        [userActionTypes.FETCH_USER_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingUser: true
            }
        },
        [userActionTypes.FETCH_USER_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingUser: false,
                user: payload.user,
            }
        },
        [userActionTypes.FETCH_USER_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                user: null,
                fetchingUser: false
            }
        },

        [userActionTypes.FETCH_USER_BY_ID_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingOtherUser: true
            }
        },
        [userActionTypes.FETCH_USER_BY_ID_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingOtherUser: false,
                otherUser: payload.user,
            }
        },
        [userActionTypes.FETCH_USER_BY_ID_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                otherUser: null,
                fetchingOtherUser: false
            }
        },

        [userActionTypes.UPDATE_USER_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                updatingUser: true
            }
        },
        [userActionTypes.UPDATE_USER_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                updatingUser: false,
                user: payload.user,
            }
        },
        [userActionTypes.UPDATE_USER_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                updatingUser: false
            }
        },
    },
    initialState
)
