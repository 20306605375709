import {chatActionTypes} from './constants';
import FirebaseService from "../../../utils/FirebaseService";
import {chat} from "./index";

export const loadMessages = (chatId) => {
    return (dispatch) => {

        FirebaseService.shared.on(chatId,(message) => {
            console.log(message)

            dispatch({
                type: chatActionTypes.CHAT_LOAD_MESSAGES_SUCCESS,
                payload: {
                    messages: message
                }
            });
        }, (err) => {
            dispatch({
                type: chatActionTypes.CHAT_LOAD_MESSAGES_ERROR,
                payload: {
                    error: err
                }
            });
        });
    }
}

export const updateMessage = text => {
    return (dispatch) => {
        dispatch({
            type: chatActionTypes.CHAT_MESSAGE_UPDATE,
            payload: {
                text: text
            }
        });
    }
}

export const sendMessage = (chatId, message) => {
    return (dispatch) => {

        let currentUser = FirebaseService.shared.uid;
        let createdAt = new Date().getTime();
        let chatMessage = {
            text: message,
            createdAt: createdAt,
            user: {
                id: currentUser,
            }
        };

        FirebaseService.shared.send(chatId, [chatMessage], (error) => {
            if (error) {
                dispatch({
                    type: chatActionTypes.CHAT_MESSAGE_ERROR,
                    payload: {
                        error: error.message
                    }
                });
            } else {
                dispatch({
                    type: chatActionTypes.CHAT_MESSAGE_SUCCESS,
                });
            }
        });
    }
}

export const createChat = (otherUserId, history) => {
    return (dispatch) => {
        dispatch({
            type: chatActionTypes.CHAT_CREATION_REQUEST,
        });

        FirebaseService.shared.createChat(otherUserId, (error, chatId) => {
            if (error) {
                dispatch({
                    type: chatActionTypes.CHAT_CREATION_FAILURE,
                    payload: {
                        error: error.message
                    }
                });
            } else {
                dispatch({
                    type: chatActionTypes.CHAT_CREATION_SUCCESS,
                });

                history.push({pathname: '/chat', state: {chatId: chatId, userId: otherUserId}});
            }
        });
    }
}

export const loadChats = () => {
    return (dispatch) => {
        dispatch({
            type: chatActionTypes.CHATS_LOAD_REQUEST,
        });

        FirebaseService.shared.onLoadChats((chats) => {
            dispatch({
                type: chatActionTypes.CHATS_LOAD_SUCCESS,
                payload: {
                    chats: chats
                }
            });
        }, (err) => {
            dispatch({
                type: chatActionTypes.CHATS_LOAD_ERROR,
                payload: {
                    error: err
                }
            });
        });
    }
}
