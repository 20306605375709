import React from 'react';
import './PropertyCard.css';
import { FiMapPin } from 'react-icons/fi';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import homeTypeLabel from '../../utils/homeTypeLabel';

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function PropertyCard({property, onClick}) {
    let imageUrl = property?.pictures && property?.pictures.length > 0 ? property.pictures[0] : 'homepage_header.png';

    return (
        <div className='propertycard-container' onClick={onClick}>
            <div className='propertycard-touchableHeader'>
                <div style={{backgroundImage: `url(${imageUrl})`}}
                    className='propertycard-header'
                >
                    <div className='propertycard-headerContent'>
                        <div className='propertycard-headerLeftLabelContainer'>
                            <div className='propertycard-headerLeftLabelText'>
                                {property?.updatedAt ? timeAgo.format(Date.parse(property.updatedAt)) : ''}
                            </div>
                        </div>

                        <div className='propertycard-headerRightLabelContainer'>
                        </div>
                    </div>
                </div>
            </div>

            <div className='propertycard-addressContainer'>
                <FiMapPin color={"#8F92A1"}/>
                <div className='propertycard-addressText'>{property?.address? property.address : 'sin dirección'}</div>
            </div>

            <div className='propertycard-statusContainer'>
                <div className='propertycard-statusIcon'/>
                <div className='propertycard-statusText'>Se {property?.listingType === 'rent'? 'Renta' : 'Vende'} {homeTypeLabel(property?.homeType)}</div>
            </div>
        </div>
    );
}

export default PropertyCard;
