import React, {useEffect} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import Navbar from "./components/Navbar/Navbar";
import MobileRedirectBanner from "./components/MobileRedirectBanner/MobileRedirectBanner";
import Footer from "./components/Footer/Footer";
import Search from "./pages/Search/Search";
import Login from "./pages/Login/Login";
import Sell from "./pages/Sell/Sell";
import {useSelector, useDispatch} from "react-redux";
import Register from "./pages/Register/Register";
import AsyncStorage from "@callstack/async-storage";
import {actions} from "./store";
import SearchAgent from "./pages/SearchAgent/SearchAgent";
import AgentProfile from "./pages/AgentProfile/AgentProfile";
import FirebaseService from "./utils/FirebaseService";
import MyProfile from "./pages/MyProfile/MyProfile";
import Support from "./pages/Support/Support";
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import Chat from './pages/Chat/Chat';
import ContactList from './pages/ContactList/ContactList';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
    const isLoggedIn = useSelector(state => state.user.loggedIn);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();

    useEffect(function () {
        AsyncStorage.getItem("token").then(token => {
            if (token) {
                dispatch(actions.user.loginToken(token));
            }
        });
    }, []);

    useEffect( () => {
        AsyncStorage.getItem('firebaseToken').then(userToken => {
                if (userToken) {
                    FirebaseService.shared.login(userToken,
                        () => {
                            console.warn('logged in firebase');
                        },
                        (err) => {
                            console.warn('failed to log in firebase', err.message);
                            dispatch(actions.user.renewToken());
                        });
                }
            }
        );
    }, [isLoggedIn, token]);

    function redirectToMobileApp(history, match) {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
          hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
          hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
          const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
          if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
          } else if ("orientation" in window) {
            hasTouchScreen = true; // deprecated, but good fallback
          } else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = navigator.userAgent;
            hasTouchScreen =
              /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
              /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
          }
        }

        if (hasTouchScreen) {
          window.location.href = 'loro://' + match.url;

          setTimeout(function(){
            history.push('/search?property=' + match.params.id);
          },1000);
        } else {
          history.push('/search?property=' + match.params.id);
        }
    }

    return (
        <Router>
            <MobileRedirectBanner/>

            <Switch>
                <Route
                    path={'/property/app/:id'}
                    render={({ history, match }) =>
                        {
                            redirectToMobileApp(history, match);
                            return null;
                        }
                    }
                />
                <PrivateRoute path="/sell">
                    <Navbar/>
                    <Sell/>
                </PrivateRoute>
                <PrivateRoute path="/chat">
                    <Navbar/>
                    <Chat/>
                </PrivateRoute>
                <PrivateRoute path="/contacts">
                    <Navbar/>
                    <ContactList/>
                </PrivateRoute>
                <Route path="/search">
                    <Navbar/>
                    <Search/>
                </Route>
                <Route path="/search-agent">
                    <Navbar/>
                    <SearchAgent/>
                </Route>
                <Route path="/agent/:id">
                    <Navbar/>
                    <AgentProfile/>
                </Route>
                <Route path="/help">
                    <Navbar/>
                    <Support/>
                </Route>
                <PrivateRoute path="/profile">
                    <Navbar/>
                    <MyProfile/>
                </PrivateRoute>
                <PrivateRoute path="/edit-profile">
                    <Navbar/>
                    <AccountSettings/>
                </PrivateRoute>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/register">
                    <Register/>
                </Route>
                <Route path="/recover-password">
                    <RecoverPassword/>
                </Route>
                <Route path="/privacy-policy">
                    <Navbar/>
                    <PrivacyPolicy/>
                </Route>
                <Route path="/">
                    <Navbar/>
                    <HomePage/>
                </Route>
            </Switch>
        </Router>
    );
}

function PrivateRoute({ children, ...rest }) {
    const loggedIn = useSelector(state => state.user.loggedIn);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                loggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default App;
