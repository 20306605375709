import AsyncStorage from '@callstack/async-storage';
import {http} from "../../utils/http";
import FirebaseService from "../../utils/FirebaseService";

export const userService = {
    login,
    renewToken,
    logout,
    register,
    requestConfirmationCode,
    checkIfEmailIsAvailable,
    changePassword,
    userDetails,
    anotherUserDetails,
    addPropertyToFavorites,
    removePropertyFromFavorites,
    getFavoriteProperties,
    updateUser,
    addImageToUser,
    getMyListings,
    deleteAccount,
};


/**
 * Performs the login request and returns the user.
 * @param email
 * @param password
 * @returns {Promise<any> | Promise<*>} user object
 */
async function login(email, password) {

    return http.post('users/login', {
        email: email,
        password: password,
    })
        .then(async function (response) {
            const token = response.data.token;
            const firebaseToken = response.data.firebaseToken;

            await AsyncStorage.setItem("token", token);
            await AsyncStorage.setItem("firebaseToken", firebaseToken);
            //await FirebaseService.shared.login(firebaseToken, ()=> console.log('logged in firebase'), (error) => console.log('failed to log in firebase', error));

            return response;
        })
        .catch(function (error) {
            console.log(error)
            throw error;
        });
}

async function renewToken() {

    return http.post('users/renew-firebase-token', {
    })
        .then(async function (response) {
            const token = response.data.token;
            const firebaseToken = response.data.firebaseToken;

            await AsyncStorage.setItem("token", token);
            await AsyncStorage.setItem("firebaseToken", firebaseToken);
            //await FirebaseService.shared.login(firebaseToken, ()=> console.log('logged in firebase'), ()=> console.log('failed to log in firebase'));

            return response;
        })
        .catch(function (error) {
            console.log(error)
            throw error;
        });
}

/**
 * Performs the logout action.
 */
function logout() {
    FirebaseService.shared.off();
    AsyncStorage.removeItem('token');
    AsyncStorage.removeItem('firebaseToken');
}

function register(username, email, phone, password, confirmationCode) {

    return http.post('users/register', {
        username: username,
        email: email,
        password: password,
        phone: phone,
        otp: confirmationCode
    })
        .then(async function (response) {
            const token = response.data.token;
            const firebaseToken = response.data.firebaseToken;

            await AsyncStorage.setItem("token", token);
            await AsyncStorage.setItem("firebaseToken", firebaseToken);
            //await FirebaseService.shared.login(firebaseToken, ()=> console.log('logged in firebase'), ()=> console.log('failed to log in firebase'));

            return response;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function requestConfirmationCode(phone, email, useEmail = false) {
    return http.post('users/otp', {
        phone,
        email,
        useEmail
    })
        .then(async function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function checkIfEmailIsAvailable(email) {
    return http.get('users/check-email/' + email)
        .then(async function (response) {
            return response.status;
        })
        .catch(function (error) {
            throw error.status;
        });
}

function changePassword(email, password, confirmationCode) {
    return http.put('users/change-password', {
        email: email,
        password: password,
        otp: confirmationCode
    })
        .then(async function (response) {
            return response.status;
        })
        .catch(function (error) {
            console.log(error);
            throw error.status;
        });
}

function userDetails() {
    return http.get('users/me')
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function anotherUserDetails(id) {
    return http.get('users/' + id)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function addPropertyToFavorites(propertyId) {
    return http.post('users/favorites/' + propertyId)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function removePropertyFromFavorites(propertyId) {
    return http.delete('users/favorites/' + propertyId)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function getMyListings() {
    return http.get('properties/my-listings/get')
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function getFavoriteProperties() {
    return http.get('users/favorites/get')
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
}

function updateUser(picture, username, bio, email, phone, hidePhone, address, city, state) {
    return http.put('users', {
        picture, username, bio, email, phone, hidePhone, address, city, state
    })
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

function addImageToUser(image) {
    var photo = {
        uri: image.path,
        type: image.mime,
        name: image.modificationDate || image.creationDate,
    };

    var bodyFormData = new FormData();
    bodyFormData.append("image", photo);

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    };

    return http.post('users/media', bodyFormData , config)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

function deleteAccount(otp) {
    return http.delete('users/myself/' + otp)
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}