import React from 'react';
import './SecondaryButton.css';

function SecondaryButton({label, onClick}) {
    return (
        <button className='secondarybutton-container' type='button' onClick={onClick}>
            <div className='secondarybutton-label'>
                {label}
            </div>
        </button>
    );
}

export default SecondaryButton;
