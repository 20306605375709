import React from 'react';
import './FilterButton.css';

function FilterButton({label, onClick}) {
    return (
        <div className='filterbutton-container' onClick={onClick}>
            <div className='filterbutton-label'>
                {label}
            </div>
        </div>
    );
}

export default FilterButton;
