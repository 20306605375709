import {http} from "../../utils/http";

export const agentService = {
    fetchAgents,
};

async function fetchAgents() {
    return http.get('users/agents')
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
