import React, {useState} from 'react';
import './SendSupportMessage.css';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import Input from "../Input/Input";
import {useDispatch} from "react-redux";
import {actions} from "../../store/modules";
import { useHistory } from "react-router-dom";


function SendSupportMessage({user}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [name, setName] = useState(user? user.username : null);
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState(user? user.email : null);
    const [phone, setPhone] = useState(user? user.phone : null);

    function submit() {
        dispatch(actions.app.sendSupportMessage(name, phone, email, message)).then(res => {
            alert('Mensage enviada! Responderemos lo antes posible.');
            history.push("/");
        })
    }

    return (
        <div className='sendsupportmessage-container'>
            <div className='sendsupportmessage-title'>
                Contactar
            </div>

            <Input placeholder={'Anne'} type={'text'} label='Nombre' onChange={setName} value={name}/>
            <Input placeholder={'5555-555555'} type={'email'} label='Número de Teléfono' onChange={setPhone} value={phone}/>
            <Input placeholder={'ejemplo@ejemplo.com'} type={'email'} label='E-mail' onChange={setEmail} value={email}/>
            <Input placeholder={'Escribe tu mensaje...'} type={'text'} label='Mensaje' onChange={setMessage} value={message}/>

            <PrimaryButton label={'Enviar'} onClick={submit}/>
        </div>

    );
}

export default SendSupportMessage;
