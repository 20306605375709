import {handleActions} from 'redux-actions'
import {ratingActionTypes} from './constants'

const initialState = {
    requesting: false,
    requestSuccess: false,
    error: '',

    creatingRating: false,
    createRatingSuccess: false,

    fetchOwnRating: false,
    fetchOwnRatingSuccess: false,

    ratings: [],
    average: 0,
    userRating: null
};

export default handleActions(
    {
        [ratingActionTypes.FETCH_RATINGS_REQUEST]: (state = initialState, action) => {
            const p = action.payload;
            return {
                ...state,
                requesting: true,
                requestSuccess: false,
                error: '',

                ratings: [],
            }
        },

        [ratingActionTypes.FETCH_RATINGS_SUCCESS]: (state = initialState, action) => {
            const p = action.payload;
            return {
                ...state,
                requesting: false,
                requestSuccess: true,
                error: '',
                ratings: p.ratings
            }
        },

        [ratingActionTypes.FETCH_RATINGS_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                requesting: false,
                requestSuccess: false,
                error: 'Failed to load ratings.'
            }
        },
        [ratingActionTypes.FETCH_RATINGS_AVERAGE_REQUEST]: (state = initialState, action) => {
            const p = action.payload;
            return {
                ...state,
                average: 0,
            }
        },

        [ratingActionTypes.FETCH_RATINGS_AVERAGE_SUCCESS]: (state = initialState, action) => {
            const p = action.payload;

            return {
                ...state,
                average: p.average
            }
        },

        [ratingActionTypes.FETCH_RATINGS_AVERAGE_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                average: 0
            }
        },
        [ratingActionTypes.CREATE_RATING_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                creatingRating: true,
                createRatingSuccess: false,
            }
        },
        [ratingActionTypes.CREATE_RATING_SUCCESS]: (state = initialState, action) => {
            const p = action.payload;
            return {
                ...state,
                creatingRating: false,
                createRatingSuccess: true,
                userRating: p.createdRating
            }
        },
        [ratingActionTypes.CREATE_RATING_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                creatingRating: false,
                createRatingSuccess: false,
                userRating: null,
            }
        },
        [ratingActionTypes.FETCH_OWN_RATING_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchOwnRating: true,
                fetchOwnRatingSuccess: false,
            }
        },
        [ratingActionTypes.FETCH_OWN_RATING_SUCCESS]: (state = initialState, action) => {
            const p = action.payload;
            return {
                ...state,
                fetchOwnRating: false,
                fetchOwnRatingSuccess: true,
                userRating: p.rating
            }
        },
        [ratingActionTypes.FETCH_OWN_RATING_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                fetchOwnRating: false,
                fetchOwnRatingSuccess: false,
                userRating: null,
            }
        },
        [ratingActionTypes.RESET_RATINGS_SCREEN]: (state = initialState, action) => {
            return {
                ...state,
                ratings: [],
                userRating: null
            }
        },
    },
    initialState
)
