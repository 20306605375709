import React from 'react';
import './Select.css';

function Select({type='text', placeholder, onChange, onBlur, label, children, value}) {
    return (
        <div className='select-container'>
            <div className='select-label'>
                {label}
            </div>
            <select
                value={value}
                placeholder={placeholder}
                onChange={evt => onChange(evt.target.value)}
                onBlur={onBlur}
                className='select-el'
            >
                {children}
            </select>
        </div>
    );
}

export default Select;
