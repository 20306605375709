import React, {useState, useEffect, useRef} from 'react';
import './SearchBar.css';
import { FaSearch } from 'react-icons/fa';
import PlacesAutocomplete from 'react-places-autocomplete';

function SearchBar({containerStyle, inputStyle, defaultValue = '', onSubmit = () => {}, onChange = (text) => {}, suggestInput = false}) {
    const autocompleteRef = useRef(null);
    const [results, setResults] = useState([]);
    const [address, setAddress] = useState(defaultValue);
    const [hideSuggestions, setHideSuggestions] = useState(false); // to hide when clicking on forced suggestion

    useEffect(() => {
        setAddress(defaultValue);
    }, [defaultValue])

    const searchOptions = {
        componentRestrictions: {country: 'co'}
    }

    function keyUp(evt) {
        if (evt.keyCode === 13) {
            evt.preventDefault();
            onSubmit(evt.target.value);
        }
    }

    function handleChange(address) {
        setAddress(address);
        setHideSuggestions(false);
    };

    function handleSelect(address) {
        setHideSuggestions(true);
        setAddress(address);
        onChange(address);
        onSubmit(address);
        setResults([]);
        autocompleteRef.current.clearSuggestions();
    };

    return (
        <PlacesAutocomplete
            ref={autocompleteRef}
            className='searchbar-wrapper'
            onChange={handleChange}
            onSelect={handleSelect}
            value={address || ''}
            shouldFetchSuggestions={address?.length > 3}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                //clone suggestions array
                const suggestionsClone = [...suggestions];

                if (suggestions.length === 0 && address?.length > 3) {
                    suggestionsClone.push({
                        description: 'No se encontraron resultados',
                        active: false,
                        placeId: 'input',
                        structured_formatting: {
                            main_text: 'No se encontraron resultados',
                            secondary_text: 'Dirección ingresada'
                        },
                        disabled: true
                    })
                }

                if (address && suggestInput && suggestions) {
                    suggestionsClone.push({
                        description: address,
                        active: false,
                        placeId: 'input',
                        structured_formatting: {
                            main_text: address,
                            secondary_text: 'Dirección ingresada'
                        }
                    });
                }

                return (
                <div>
                    <div className="searchbar-bar" style={containerStyle}>
                        <FaSearch className="searchbar-icon"/>
                        <input
                            {...getInputProps()}
                            className="searchbar-input"
                            placeholder='Ingrese una dirección, vecindario, ciudad o código postal'
                            style={inputStyle}
                            onKeyUp={keyUp}
                        />
                    </div>
                    {
                        !hideSuggestions && suggestionsClone.length > 0 && (
                            <div className="searchbar-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestionsClone.map(suggestion => {
                                    const className = 'searchbar-dropdown-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                            onClick={() => {
                                                if (suggestion.disabled) return;
                                                handleSelect(suggestion.description);
                                            }}
                                        >
                                            <span>
                                                {address === suggestion.description && <strong>O mantén tu dirección: </strong>}
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                </div>
                )}
            }
        </PlacesAutocomplete>
    );
}

export default SearchBar;
