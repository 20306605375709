import React, {useEffect, useState, useRef} from 'react';
import './Chat.css';
import IconButton from "../../components/IconButton/IconButton";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import ChatInput from "../../components/ChatInput/ChatInput";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import FirebaseService from "../../utils/FirebaseService";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

function Chat() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const messages = useSelector(state => state.chat.messages);
    const error = useSelector(state => state.chat.loadMessagesError);
    const [user, setUser] = useState(null);
    const scrollViewRef = useRef(null);

    useEffect(() => {
        if (location?.state?.chatId && location?.state?.userId) {
            dispatch(actions.chat.loadMessages(location.state.chatId));
            dispatch(actions.user.fetchUserById(location.state.userId)).then(u => {
                setUser(u)
            });
        } else {
            history.push('/');
        }
    }, [dispatch, location?.state?.chatId, location?.state?.userId]);

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({top: scrollViewRef.current.scrollHeight, behavior: 'smooth'});
        } 
    }, [messages]);


    function renderList() {
        if (!messages || !messages.length) return null;

        return messages
            .sort((a, b) => a.timestamp - b.timestamp) // sort by timestamp
            .map(c => <ChatMessage message={c.text} me={c.user.id === FirebaseService.shared.uid} key={c._id} timestamp={c.timestamp}/>);
    }

    return (
        <div className='chat-container'>
            <div className='chat-header'>
                <FaChevronLeft size={24} color={'#88D203'} onClick={() => history.push("/contacts")}/>
                <div className='chat-headerTitle'>{user?.username}</div>
            </div>

            <div className='chat-scrollView'
                ref={scrollViewRef}
                onContentSizeChange={() => scrollViewRef.current.scrollToEnd({animated: true})}>
                <div className='chat-messageList'>
                    {renderList()}
                </div>
            </div>

            <div className='chat-inputView'>
                <ChatInput chatId={location?.state?.chatId}/>
            </div>
        </div>
    );
}

export default Chat;
