import React from 'react';
//import {compose, withProps, withState, lifecycle, withHandlers} from "recompose";
//import {withScriptjs, withGoogleMap, GoogleMap, Polygon, Polyline, OverlayView, Marker} from "react-google-maps";
//import PriceMarker from "../PriceMarker/PriceMarker";
//import ListingTypeMarker from "../ListingTypeMarker/ListingTypeMarker";

//const LATITUDE = 4.60971;
//const LONGITUDE = -74.08175;

export const markerTypes = {
    LISTING_TYPE_MARKER: 'LISTING_TYPE_MARKER',
    PRICE_MARKER: 'PRICE_MARKER',
};
/*

const Map = React.forwardRef(compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAYNGTQVlz05OTWG8l5TJA6zCnlEFQUeEA",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%`, width: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    withState("stateMarkers", "setMarkers", []),
    withHandlers({
        createMarker: (position) => {
        },

        clearMarkers: (props) => () => {
            console.log(props.stateMarkers)
            for (let m of props.stateMarkers) {
                console.log(m)
                m.setMap(null);
            }
        },

        onRef: () => {
        }
    }),
    lifecycle({
        componentDidUpdate(prevProps, prevState) {
            console.log(this.props.forwardedRef.current)

            if (this.props.markers !== prevProps.markers) {

                console.log(this.props.stateMarkers.length)

                if (this.props.stateMarkers.length > 0) {
                    this.props.clearMarkers(this.props);
                }

                if (this.props.markers.length > 0) {
                    //render markers
                    let newMarkers = [];

                    this.props.markers.forEach((m, i) => {
                        const marker = new window.google.maps.Marker({
                            position: {lat: m.latitude, lng: m.longitude},
                            map: this.props.forwardedRef,
                        });
                        newMarkers.push(marker);
                    })

                    this.props.setMarkers(newMarkers);
                }
            }
        }
    })
    )((props) =>
        <GoogleMap
            id={'map'}
            key={props.key}
            ref={props.forwardedRef}
            defaultZoom={10}
            defaultCenter={{ lat: LATITUDE, lng: LONGITUDE }}
            center={props.center? props.center : { lat: LATITUDE, lng: LONGITUDE }}
            zoom={props.zoom? props.zoom : 10}
            onDragEnd={props.onDragEnd}
            onCenterChanged={() => props.onCenterChanged(true)}
            onZoomChanged={() => props.onCenterChanged(true)}
            options={{ scrollwheel: true}}
            onClick={props.onClick}
            onDrag={props.onDrag}
        >
            {
                props.markers.map(
                    (m, i) =>
                        props.markerType === markerTypes.PRICE_MARKER ?
                            <PriceMarker
                                key={i}
                                position={{ lat: m.latitude, lng: m.longitude }}
                                amount={m.price? m.price : 0}
                                property={m}
                                onClick={() => props.onMarkerClick(m)}
                            />
                            :
                            <ListingTypeMarker
                                key={i}
                                position={{ lat: m.latitude, lng: m.longitude }}
                                type={m.listingType}
                                property={m}
                                onClick={() => props.onMarkerClick(m)}
                            />
                )
            }
            {props.polygons && props.polygons.length > 0 && props.polygons.map(polygon => (
                <Polygon
                    key={polygon.id}
                    path={polygon.coordinates}
                    holes={polygon.holes}
                    options={{
                        strokeColor: "#88D203",
                        fillColor: "rgba(136,210,3, 0.5)",
                        strokeWidth: 1
                    }}
                />
            ))}
            {props.polylines && (
                <Polyline
                    geodesic={true}
                    key={props.polylines.id}
                    path={props.polylines.coordinates}
                    holes={props.polylines.holes}
                    options={{
                        strokeColor: "#88D203",
                        fillColor: "rgba(136,210,3,0.5)",
                        strokeWidth: 1
                    }}
                />
            )}

        </GoogleMap>
));

export default React.forwardRef(({...props}, ref) =>  <Map {...props} forwardedRef={ref} />);
*/
