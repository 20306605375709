import {http} from "../../utils/http";

export const supportService = {
    sendSupportMessage,
};

async function sendSupportMessage(name, phone, email, message) {
    return http.post('support/', {name, phone, email, message})
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
