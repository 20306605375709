import React, {useState, useEffect} from 'react';
import './PriceFilterModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";

Modal.setAppElement(document.getElementById('root'));

function PriceFilterModal({isOpen, onRequestClose}) {

    const dispatch = useDispatch();

    const priceMinFilterRent = useSelector(state => state.property.priceMinFilterRent);
    const priceMaxFilterRent = useSelector(state => state.property.priceMaxFilterRent);
    const priceMinFilterSale = useSelector(state => state.property.priceMinFilterSale);
    const priceMaxFilterSale = useSelector(state => state.property.priceMaxFilterSale);

    const listingTypeFilter = useSelector(state => state.property.listingTypeFilter);

    const [minRent, setMinRent] = useState(priceMinFilterRent? priceMinFilterRent : 0);
    const [maxRent, setMaxRent] = useState(priceMaxFilterRent? priceMaxFilterRent : 10000000);
    const [minSale, setMinSale] = useState(priceMinFilterSale? priceMinFilterSale : 0);
    const [maxSale, setMaxSale] = useState(priceMaxFilterSale? priceMaxFilterSale : 2000000000);

    function changePriceFilter(priceMinFilterRent, priceMaxFilterRent, priceMinFilterSale, priceMaxFilterSale) {
        dispatch(actions.property.changeFilters(listingTypeFilter, priceMinFilterRent, priceMaxFilterRent, priceMinFilterSale, priceMaxFilterSale, undefined, undefined,undefined, undefined, undefined))
    }

    function formatThousands(x) {
        if (!x) return 0;
        //if (!isNaN(x)) x = parseFloat(x).toFixed(2);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Listing Type Filter'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='pricefiltermodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <div className='pricefiltermodal-content'>
                <button onClick={onRequestClose} className='pricefiltermodal-close-btn'>
                    <GrClose/>
                </button>

                <div className='pricefiltermodal-options-title'>
                    Precio
                </div>

                {
                    (!listingTypeFilter || listingTypeFilter.length === 0 || listingTypeFilter.indexOf('rent') !== -1) &&
                    <div className='pricefiltermodal-options'>
                        <div className='pricefiltermodal-options-title'>
                            Alquilar
                        </div>
                        <InputRange
                            step={10000}
                            maxValue={10000000}
                            minValue={0}
                            value={{min: minRent, max: maxRent}}
                            onChangeComplete={({min, max}) => changePriceFilter(min, max, minSale, maxSale)}
                            onChange={({min, max}) => {
                                setMinRent(min);
                                setMaxRent(max);
                            }}
                            formatLabel={formatThousands}
                        />
                    </div>
                }

                {
                    (!listingTypeFilter || listingTypeFilter.length === 0 || listingTypeFilter.indexOf('sale') !== -1) &&
                    <div className='pricefiltermodal-options'>
                        <div className='pricefiltermodal-options-title'>
                            Venta
                        </div>
                        <InputRange
                            step={2000000}
                            maxValue={2000000000}
                            minValue={0}
                            value={{min: minSale, max: maxSale}}
                            onChangeComplete={({min, max}) => changePriceFilter(minRent, maxRent, min, max)}
                            onChange={({min, max}) => {setMinSale(min); setMaxSale(max);}}
                            formatLabel={formatThousands}
                        />
                    </div>
                }

                <PrimaryButton label={'Aplicar'} onClick={onRequestClose}/>
            </div>
        </Modal>
    );
}

export default PriceFilterModal;
