export const ratingActionTypes = {
    FETCH_RATINGS_REQUEST: 'RATING/FETCH_RATINGS_REQUEST',
    FETCH_RATINGS_SUCCESS: 'RATING/FETCH_RATINGS_SUCCESS',
    FETCH_RATINGS_FAILURE: 'RATING/FETCH_RATINGS_FAILURE',

    FETCH_RATINGS_AVERAGE_REQUEST: 'RATING/FETCH_RATINGS_AVERAGE_REQUEST',
    FETCH_RATINGS_AVERAGE_SUCCESS: 'RATING/FETCH_RATINGS_AVERAGE_SUCCESS',
    FETCH_RATINGS_AVERAGE_FAILURE: 'RATING/FETCH_RATINGS_AVERAGE_FAILURE',

    CREATE_RATING_REQUEST: 'RATING/CREATE_RATING_REQUEST',
    CREATE_RATING_SUCCESS: 'RATING/CREATE_RATING_SUCCESS',
    CREATE_RATING_FAILURE: 'RATING/CREATE_RATING_FAILURE',

    FETCH_OWN_RATING_REQUEST: 'RATING/FETCH_OWN_RATING_REQUEST',
    FETCH_OWN_RATING_SUCCESS: 'RATING/FETCH_OWN_RATING_SUCCESS',
    FETCH_OWN_RATING_FAILURE: 'RATING/FETCH_OWN_RATING_FAILURE',

    RESET_RATINGS_SCREEN: 'RATING/RESET_RATING_SCREEN',

};
