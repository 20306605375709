import {handleActions} from 'redux-actions';
import {agentActionTypes} from "./constants";

const initialState = {
    agents: [],
    fetchingAgents: false
};

/**
 * Action handler
 */
export default handleActions(
    {
        [agentActionTypes.FETCH_AGENTS_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingAgents: true
            }
        },
        [agentActionTypes.FETCH_AGENTS_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingAgents: false,
                agents: payload.agents,
            }
        },
        [agentActionTypes.FETCH_AGENTS_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                agents: [],
                fetchingAgents: false
            }
        },
    },
    initialState
)
