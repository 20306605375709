import React, { useEffect } from 'react';
import './MobileRedirectBanner.css';
import { GrClose } from 'react-icons/gr';
import Cookies from 'universal-cookie';
import appStoreBadge from '../../app-store-badge.svg';

function MobileRedirectBanner() {
    const cookies = new Cookies();

    useEffect(() => {
        if (cookies.get('mobile-banner')) {
            document.getElementById('mobile-banner').style.display = 'none';
        } 
    }, []);

    function closeBanner() {
        const expire = new Date();
        expire.setDate(expire.getDate() + 7);
        document.getElementById('mobile-banner').style.display = 'none';
        cookies.set('mobile-banner', 'closed', { path: '/',  expires: expire });
    }

    return (
        <div className="mobileBanner-container" id={'mobile-banner'}>
            <div className="mobileBanner-left">
                <a href='https://play.google.com/store/apps/details?id=com.loro' target="_blank" rel="noopener noreferrer">
                    <img src='/google-play-badge.png' alt='google play badge' className='mobileBanner-google-badge' />
                </a>
                <a href='https://apps.apple.com/us/app/loro-bienes-raices/id1544984020' target="_blank" rel="noopener noreferrer">
                    <img src={appStoreBadge} alt='app store badge' className='mobileBanner-apple-badge' />
                </a>
                <h3 className="mobileBanner-text">Prueba nuestra aplicación móvil</h3>
            </div>
            <a className="mobileBanner-close" onClick={closeBanner}><GrClose style={{ stroke: '#fff' }}/></a>
        </div>
    );
}

export default MobileRedirectBanner;
