import {propertyActionTypes} from './constants';
import {propertyService} from "../../services/propertyService";
import FirebaseService from "../../../utils/FirebaseService";

export const fetchProperties = (region) => {
    const properties = [];
    return {type: propertyActionTypes.FETCH_PROPERTIES, payload: {properties}};
};

export const fetchPropertyById = (id) => {
    return function (dispatch) {
        dispatch({
            type: propertyActionTypes.FETCH_PROPERTY_BY_ID_REQUEST
        });

        return propertyService.searchPropertyById(id).then(
            res => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTY_BY_ID_SUCCESS,
                    payload: {
                        propertyById: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTY_BY_ID_FAILURE,
                    payload: {
                        propertyById: null
                    }
                });
                return error;
            }
        );
    }
};

export const changeFilters = (listingType = [], priceMinFilterRent = null, priceMaxFilterRent = null, priceMinFilterSale = null, priceMaxFilterSale = null, homeType = [], beds = null, baths = null) => {
    return function (dispatch) {
        return dispatch({
            type: propertyActionTypes.CHANGE_FILTERS,
            payload: {
                filters: {listingType, priceMinFilterRent, priceMaxFilterRent, priceMinFilterSale, priceMaxFilterSale, homeType, beds, baths}
            }
        });
    }
};

export const searchPropertiesInsideArea = (polygon) => {
    return function (dispatch, getState) {
        dispatch({
            type: propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_REQUEST
        });

        const state = getState().app;

        return propertyService.searchPropertiesInsideArea(
            polygon,
            state.listingTypeFilter,
            state.priceMinFilterRent,
            state.priceMaxFilterRent,
            state.priceMinFilterSale,
            state.priceMaxFilterSale,
            state.homeTypeFilter,
            state.bedsFilter,
            state.bathsFilter
        ).then(
            res => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_SUCCESS,
                    payload: {
                        properties: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_FAILURE,
                    payload: {
                        properties: []
                    }
                });
                return error;
            }
        );
    }
};

export const fetchPropertiesFromAgent = (agentId) => {
    return function (dispatch) {
        dispatch({
            type: propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_REQUEST
        });

        return propertyService.fetchPropertiesFromAgent(agentId).then(
            res => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_SUCCESS,
                    payload: {
                        propertiesFromAgent: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_FAILURE,
                    payload: {
                        propertiesFromAgent: []
                    }
                });
                return error;
            }
        );
    }
};

export const createProperty = (property) => {
    return function (dispatch) {
        dispatch({
            type: propertyActionTypes.CREATE_PROPERTY_REQUEST
        });

        return FirebaseService.shared.uploadPropertyPictures(property.pictures).then(picturesUrls => {
            return propertyService.createProperty(
                {...property, pictures: picturesUrls}
            ).then(
                res => {
                    dispatch({
                        type: propertyActionTypes.CREATE_PROPERTY_SUCCESS,
                        payload: { createdProperty: res }
                    });
                    return res;
                },
                error => {
                    dispatch({
                        type: propertyActionTypes.CREATE_PROPERTY_FAILURE,
                        payload: {
                            error: "Failed to create property."
                        }
                    })
                    throw error;
                }
            );
        });

    }
};

export const updateProperty = (_id, property) => {
    return function (dispatch) {
        dispatch({
            type: propertyActionTypes.UPDATE_PROPERTY_REQUEST
        });

        return FirebaseService.shared.uploadPropertyPictures(property.pictures).then(picturesUrls => {
            return propertyService.updateProperty(
                _id, 
                {...property, pictures: picturesUrls}
            ).then(
                res => {
                    dispatch({
                        type: propertyActionTypes.UPDATE_PROPERTY_SUCCESS,
                        payload: { createdProperty: res }
                    });
                    return res;
                },
                error => {
                    dispatch({
                        type: propertyActionTypes.UPDATE_PROPERTY_FAILURE,
                        payload: {
                            error: "Failed to create property."
                        }
                    })
                    throw error;
                }
            );
        });

    }
};

export const deleteProperty = (propertyId) => {
    return function (dispatch) {
        dispatch({
            type: propertyActionTypes.DELETE_PROPERTY_REQUEST
        });

        return propertyService.deleteProperty(propertyId).then(
            res => {
                dispatch({
                    type: propertyActionTypes.DELETE_PROPERTY_SUCCESS,
                    payload: { deleteProperty: res }
                });
                return res;
            },
            error => {
                dispatch({
                    type: propertyActionTypes.DELETE_PROPERTY_FAILURE,
                    payload: {
                        error: "Failed to delete property."
                    }
                })
                throw error;
            }
        );
    }
};
