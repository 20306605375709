import React from 'react';
import './ReviewCard.css';
import { FiMapPin } from 'react-icons/fi';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import ReactStars from "react-stars";

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function ReviewCard({review, onClick}) {

    return (
        <div className='reviewcard-container' onClick={onClick}>
            <div className='reviewcard-content'>

                <div className='reviewcard-overallContainer'>
                    <div className='reviewcard-addressText'>
                        <ReactStars
                            count={5}
                            value={review?.value}
                            size={24}
                            edit={false}
                            color2={'#FAB31E'} />
                    </div>
                </div>

                <div className='reviewcard-descriptionText'>{review?.updatedAt? timeAgo.format(Date.parse(review.updatedAt)) : '...'}</div>

                <div className='reviewcard-descriptionText'>{review?.comment? review.comment : ''}</div>
            </div>
        </div>
    );
}

export default ReviewCard;
