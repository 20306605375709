import {agentActionTypes} from './constants';
import {agentService} from "../../services/agentService";

export const fetchAgents = () => {
    return function (dispatch) {
        dispatch({
            type: agentActionTypes.FETCH_AGENTS_REQUEST
        });

        return agentService.fetchAgents().then(
            res => {
                dispatch({
                    type: agentActionTypes.FETCH_AGENTS_SUCCESS,
                    payload: {
                        agents: res
                    }
                });
                return res;
            },
            error => {
                dispatch({
                    type: agentActionTypes.FETCH_AGENTS_FAILURE,
                    payload: {
                        agents: []
                    }
                });
                return error;
            }
        );
    }
};
