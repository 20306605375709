import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

// import RNFetchBlob from 'rn-fetch-blob'
// const Blob = RNFetchBlob.polyfill.Blob
// window.XMLHttpRequest = RNFetchBlob.polyfill.XMLHttpRequest
// window.Blob = Blob

class FirebaseService {

    constructor() {
        this.init();
    }

    init = () =>
        firebase.initializeApp({
            apiKey: "AIzaSyAYNGTQVlz05OTWG8l5TJA6zCnlEFQUeEA",
            authDomain: "loro-281416.firebaseapp.com",
            databaseURL: "https://loro-281416.firebaseio.com",
            projectId: "loro-281416",
            storageBucket: "loro-281416.appspot.com",
            messagingSenderId: "427244783725",
            appId: "1:427244783725:web:e5d1ccaa107296b2b15764",
            measurementId: "G-4T8MZE59CG"
        });

    login = (token, success_callback, failed_callback) => {
        firebase.auth()
            .signInWithCustomToken(token)
            .then(success_callback, failed_callback);
    }

    get refMessages() {
        return firebase.database().ref('messages');
    }

    get refChats() {
        return firebase.database().ref('chats');
    }

    get userPictureStorage() {
        return firebase.storage().ref('user');
    }

    get propertyPicturesStorage() {
        return firebase.storage().ref('property');
    }

    uploadPropertyPictures = async (pictures) => {
        let urls = [];

        for (let i = 0; i < pictures.length; i++) {
            const p = pictures[i];
            let url;

            console.log('antes do blob', p)
            if (p.file) {
                url = await this.uploadImage(this.propertyPicturesStorage.child(uuidv4()), p);
            } else {
                url = p;
            }

            urls.push(url);
        }

        return urls;
    };

    uploadImage = (ref, picture) => {
        return ref.putString(picture['data_url'], 'data_url')
            .then(function(snapshot) {
                return ref.getDownloadURL();
            }).catch(function(error){
                console.log(error)
        });
    };

    uploadUserPicture = async (picture) => {
        let url = null;
        const p = picture;

        if(p) {
            url = await this.uploadImage(this.userPictureStorage.child(uuidv4()), p);
        }
        return url;
    };

    on = (chatId, callback) =>
        firebase.database().ref('messages/' + chatId)
            .limitToLast(20)
            .on('value', snapshot => {
                let messages = [];
                if (snapshot.val()) Object.entries(snapshot.val()).forEach(item => messages.push(this.parse(item)));
                callback(messages)
            });

    onLoadChats = callback =>
        this.refChats
            .on('value', snapshot => {
                let chats = [];
                if (snapshot.val()) {

                    const main = this;
                    Object.entries(snapshot.val()).forEach(async item => {

                        async function f() {
                            if (item[1].members.indexOf(main.uid) !== -1) {

                                item = main.parseChat(item);

                                item = await firebase.database().ref('messages/' + item._id)
                                    .limitToLast(1)
                                    .once('value').then(snapshot => {
                                        let lastMessage = null;
                                        if (snapshot.val()) Object.entries(snapshot.val()).forEach(item => lastMessage = (main.parse(item)));
                                        item.lastMessage = lastMessage;
                                        return item;
                                    });

                                return item;
                            }
                        }

                        let i = await f();
                        if (i) chats.push(i);

                        callback([...chats])
                    });
                }
            });

    parse = snapshot => {
        const { timestamp: numberStamp, text, user } = snapshot[1];
        const { key: _id } = snapshot;

        const timestamp = new Date(numberStamp);

        const message = {
            _id,
            timestamp,
            text,
            user,
        };
        return message;
    };

    parseChat = snapshot => {
        const { members } = snapshot[1];

        const otherUserId = members.find(c => c !== this.uid);

        const chat = {
            _id: snapshot[0],
            otherUserId
        };
        return chat;
    };

    get uid() {
        return (firebase.auth().currentUser || {}).uid;
    }

    get timestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    send = (chatId, messages, callback) => {
        for (let i = 0; i < messages.length; i++) {
            const { text, user } = messages[i];

            const message = {
                text,
                user,
                timestamp: this.timestamp,
            };
            this.append(chatId, message, callback);
        }
    };

    createChat = (otherUserId, callback) => {

        let chat = {
            members: [this.uid, otherUserId],
        };

        this.refChats.once('value').then(snapshot => {
            if (snapshot.val()) {
                for (const c of Object.entries(snapshot.val())) {
                    const obj = c[1];
                    if (obj.members.indexOf(this.uid) !== -1 && obj.members.indexOf(otherUserId) !== -1) {
                        console.log('chat already exists');
                        callback(null, c[0]);
                        return;
                    }
                }
            }
            console.log('chat created')
            let chatRef = this.refChats.push(chat, error => {
                if(error) callback(error, null)
            });
            if (chatRef) {
                callback(null, chatRef.key)
            }
        });

    };

    append = (chatId, message, callback) => firebase.database().ref('messages/' + chatId).push(message, callback);

    off() {
        this.refMessages.off();
    }
}

FirebaseService.shared = new FirebaseService();
export default FirebaseService;
