import {handleActions} from 'redux-actions';
import {chatActionTypes} from "./constants";

const initialState = {
    sending: false,
    sendingError: null,
    message: '',
    messages: {},
    loadMessagesError: null,

    chats: [],
    loadingChats: false,
    loadChatsError: null,

};

/**
 * Action handler
 */
export default handleActions(
    {
        [chatActionTypes.CHAT_MESSAGE_LOADING]: (state = initialState, action) => {
            return { ...state, sending: true, sendingError: null }
        },
        [chatActionTypes.CHAT_MESSAGE_ERROR]: (state = initialState, action) => {
            return { ...state, sending: true, sendingError: null }
        },
        [chatActionTypes.CHAT_MESSAGE_SUCCESS]: (state = initialState, action) => {
            return { ...state, sending: false, sendingError: null, message: '' }
        },
        [chatActionTypes.CHAT_MESSAGE_UPDATE]: (state = initialState, action) => {
            const payload = action.payload;

            return { ...state, sending: false, message: payload.text, sendingError: null }
        },
        [chatActionTypes.CHAT_LOAD_MESSAGES_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return { ...state, messages: payload.messages, loadMessagesError: null }
        },
        [chatActionTypes.CHAT_LOAD_MESSAGES_ERROR]: (state = initialState, action) => {
            const payload = action.payload;

            return { ...state, messages: null, loadMessagesError: payload.error }
        },

        [chatActionTypes.CHATS_LOAD_REQUEST]: (state = initialState, action) => {
            return { ...state, loadingChats: true, loadMessagesError: null }
        },
        [chatActionTypes.CHATS_LOAD_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return { ...state, chats: payload.chats, loadingChats: false, loadMessagesError: null }
        },
        [chatActionTypes.CHATS_LOAD_ERROR]: (state = initialState, action) => {
            const payload = action.payload;

            return { ...state, loadingChats: false, loadMessagesError: payload.error }
        },
    },
    initialState
)
