import React, {useEffect, useState} from 'react';
import './MyProfile.css';
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/modules";
import UserContact from "../../components/UserContact/UserContact";
import { useHistory } from "react-router-dom";
import PropertyHorizontalCard from "../../components/PropertyHorizontalCard/PropertyHorizontalCard";
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import { ImBin } from 'react-icons/im';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DeleteAccountModal from '../../components/DeleteAccountModal/DeleteAccountModal';


function MyProfile() {
    const history = useHistory();

    const user = useSelector(state => state.user.user);
    const properties = useSelector(state => state.property.propertiesFromAgent);
    const dispatch = useDispatch();
    const [modalIsOpen, setModelIsOpen] = useState(false);

    useEffect(function () {
        dispatch(actions.user.fetchUser());
    }, []);

    useEffect(function () {
        if (user) {
            dispatch(actions.property.fetchPropertiesFromAgent(user._id));
        }
    }, [user]);

    function deleteProperty(id) {
        confirmAlert({
            title: 'Eliminar listado',
            message: '¿Estás seguro de que quieres eliminar este listado?',
            buttons: [
                {
                    label: 'Eliminar',
                    onClick: () => {
                        dispatch(actions.property.deleteProperty(id));
                        window.location.reload();
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => {}
                }
            ]
        });
    }

    function startDeleteAccount() {
        const phone = user.phone;
        dispatch(actions.user.requestConfirmationCode(phone, null, false));
        setModelIsOpen(true);
    }

    function renderPropertyList() {
        return properties.map(p => (
            <div className='myprofile-property-container'>
                <PropertyHorizontalCard property={p} onClick={() => history.push({
                    pathname: '/sell',
                    search: "?id=" + p._id
                })}/>
                <button className='myprofile-delete-btn' onClick={() => deleteProperty(p._id)}>
                    <ImBin size={24}/>
                </button>


            </div>
        ));
    }

    return (
        <div className="myprofile-container">

            <div className="myprofile-header">
            </div>


            <div className="myprofile-content">
                <UserContact user={user}/>

                <div className='myprofile-bio'>{user?.bio}</div>

                <div className='myprofile-btns-container'>
                    <SecondaryButton label={'Editar perfil'} onClick={() => history.push('/edit-profile')}/>
                    
                    <button className='myprofile-delete-btn myprofile-delete-account-btn' onClick={startDeleteAccount}>
                        <ImBin size={16}/>
                        Eliminar cuenta permanentemente
                    </button>
                </div>

                <div className='myprofile-title'>
                    Mis listados
                </div>
                <div>
                    {renderPropertyList()}
                </div>
            </div>


            <DeleteAccountModal isOpen={modalIsOpen} onRequestClose={() => {setModelIsOpen(false)}} />
        </div>
    );
}

export default MyProfile;
