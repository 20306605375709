import React from 'react';
import './IconButton.css';

function IconButton({label, onClick, icon, selected=false}) {
    return (
        <button className='iconbuttoon-container' style={selected? {opacity: 1} : {opacity: 0.3}} onClick={onClick} type='button'>
            {icon}
            <div className='iconbuttoon-label'>
                {label}
            </div>
        </button>
    );
}

export default IconButton;
