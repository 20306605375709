import React from 'react';
import PropTypes from 'prop-types';
import './ListingTypeMarker.css';

const propTypes = {
    fontSize: PropTypes.number,
};

class ListingTypeMarker extends React.Component {
    render() {
        const { type='sale', onClick, property } = this.props;
        return (
            <img
                className='listingtypemarker-container'
                src={type === 'sale'? '/sale-marker.png' : '/rent-marker.png'}
                style={{
                    width: 40, 
                    height: 40,
                }}
                onClick={() => onClick(property)}
                alt={'marker'}
            />
        );
    }
}

ListingTypeMarker.propTypes = propTypes;

export default ListingTypeMarker;
