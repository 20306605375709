import React from 'react';
import './AgentSearchResult.css';
import UserContact from "../UserContact/UserContact";
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import SmallLabel from "../SmallLabel/SmallLabel";

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function AgentSearchResult({user, lastReview}) {
    return (
        <div className="agentsearchresult-container">
            <div className="agentsearchresult-columns">

                <div className='agentsearchresult-col'>
                    <div className='agentsearchresult-col-container'>
                        <UserContact user={user}/>
                    </div>
                </div>

                <div className='agentsearchresult-map'>
                    <SmallLabel label={user.ratings?.length + ' opiniones locales'} onClick={() => {}}/>
                    <SmallLabel label={user.properties?.length + ' anuncios'} onClick={() => {}}/>
                </div>

                <div className='agentsearchresult-col agentsearchresult-review-col'>
                    <div className='agentsearchresult-col-container'>
                        <div className='agentsearchresult-review-date'>
                            Review {lastReview?.updatedAt ? timeAgo.format(Date.parse(lastReview.updatedAt)) : ''}
                        </div>

                        <div className='agentsearchresult-review-text'>
                            {lastReview.comment}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default AgentSearchResult;
