import React, { useState } from 'react';
import './Input.css';

function Input({type='text', placeholder, onChange, onSubmit, onBlur, label, value, id, name, errorMessage, disabled=false}) {
    const [touched, setTouched] = useState(false);

    function handleChange(event) {
        if (type === 'number') {
            const localValue = event.target.value.replace(/[^0-9.,]+/, '');
            onChange(localValue);
        } else {
            onChange(event.target.value);
        }
    }

    return (
        <div className='input-container'>
            <div className='input-label'>
                {label}
            </div>
            <input
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && onSubmit) {
                        onSubmit();
                    } else if (
                        (
                            type === 'number' && 
                            !(e.key.match(/[0-9.,]/) || e.key === 'Backspace' 
                                || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight'
                                || e.key === 'Delete' 
                                || (e.ctrlKey && (e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x'))
                                || (e.metaKey && (e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x'))
                            )
                        )
                    ) {
                        e.preventDefault();
                    } 
                }}
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={evt => handleChange(evt)}
                onBlur={onBlur}
                className='input-el'
                id={id}
                name={name}
                onFocus={() => {
                    setTouched(true);
                }}
                disabled={disabled}
            />
            {
                errorMessage &&
                <div className='input-error'>
                    {errorMessage}
                </div>
            }
        </div>
    );
}

export default Input;
