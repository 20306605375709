import {handleActions} from 'redux-actions';
import {propertyActionTypes} from "./constants";

const initialState = {
    properties: [],

    propertyById: null,
    fetchingPropertyById: false,

    propertiesInArea: [],
    fetchingPropertiesInArea: false,

    propertiesFromAgent: [],
    fetchingPropertiesFromAgent: false,

    createdProperty: null,
    creatingProperty: false,

    listingTypeFilter: [],
    priceMinFilterRent: 0,
    priceMaxFilterRent: 10000000,
    priceMinFilterSale: 0,
    priceMaxFilterSale: 2000000000,
    homeTypeFilter: [],
    bedsFilter: null,
    bathsFilter: null
};

/**
 * Action handler
 */
export default handleActions(
    {
        [propertyActionTypes.FETCH_PROPERTIES]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                properties: payload.properties
            }
        },
        [propertyActionTypes.CHANGE_FILTERS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                listingTypeFilter: payload.filters?.listingType,
                priceMinFilterRent: payload.filters?.priceMinFilterRent,
                priceMaxFilterRent: payload.filters?.priceMaxFilterRent,
                priceMinFilterSale: payload.filters?.priceMinFilterSale,
                priceMaxFilterSale: payload.filters?.priceMaxFilterSale,
                homeTypeFilter: payload.filters?.homeType,
                bedsFilter: payload.filters?.beds,
                bathsFilter: payload.filters?.baths,
            }
        },

        [propertyActionTypes.FETCH_PROPERTY_BY_ID_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingPropertyById: true
            }
        },
        [propertyActionTypes.FETCH_PROPERTY_BY_ID_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingPropertyById: false,
                propertyById: payload.propertyById,
            }
        },
        [propertyActionTypes.FETCH_PROPERTY_BY_ID_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                propertyById: null,
                fetchingPropertyById: false
            }
        },

        [propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingPropertiesInArea: true
            }
        },
        [propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingPropertiesInArea: false,
                propertiesInArea: payload.properties,
            }
        },
        [propertyActionTypes.FETCH_PROPERTIES_INSIDE_AREA_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                propertiesInArea: [],
                fetchingPropertiesInArea: false
            }
        },

        [propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                fetchingPropertiesFromAgent: true
            }
        },
        [propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                fetchingPropertiesFromAgent: false,
                propertiesFromAgent: payload.propertiesFromAgent,
            }
        },
        [propertyActionTypes.FETCH_PROPERTIES_FROM_AGENT_FAILURE]: (state = initialState, action) => {
            return {
                ...state,
                propertiesFromAgent: [],
                fetchingPropertiesFromAgent: false
            }
        },

        [propertyActionTypes.CREATE_PROPERTY_REQUEST]: (state = initialState, action) => {
            return {
                ...state,
                creatingProperty: true
            }
        },
        [propertyActionTypes.CREATE_PROPERTY_SUCCESS]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                creatingProperty: false,
                createdProperty: payload.createdProperty,
            }
        },
        [propertyActionTypes.CREATE_PROPERTY_FAILURE]: (state = initialState, action) => {
            const payload = action.payload;

            return {
                ...state,
                createdProperty: null,
                creatingProperty: false
            }
        },
    },
    initialState
)
