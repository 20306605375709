import React from 'react';
import './PropertyHorizontalCard.css';
import { FiMapPin } from 'react-icons/fi';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import homeTypeLabel from '../../utils/homeTypeLabel';

TimeAgo.addLocale(es);

const timeAgo = new TimeAgo('es-ES');

function PropertyHorizontalCard({property, onClick}) {
    let imageUrl = property?.pictures && property?.pictures.length > 0 ? property.pictures[0] : 'homepage_header.png';

    return (
        <div className='propertyhorizontalcard-container' onClick={onClick}>
            <div className='propertyhorizontalcard-touchableHeader'>
                <div style={{backgroundImage: `url(${imageUrl})`}}
                    className='propertyhorizontalcard-header'
                >
                    <div className='propertyhorizontalcard-headerContent'>
                        <div className='propertyhorizontalcard-headerLeftLabelContainer'>
                            <div className='propertyhorizontalcard-headerLeftLabelText'>
                                {property?.updatedAt ? timeAgo.format(Date.parse(property.updatedAt)) : ''}
                            </div>
                        </div>

                        <div className='propertyhorizontalcard-headerRightLabelContainer'>
                        </div>
                    </div>
                </div>
            </div>

            <div className='propertyhorizontalcard-content'>

                <div className='propertyhorizontalcard-addressContainer'>
                    <div className='propertyhorizontalcard-addressText'>{property?.address? property.address : 'sin dirección'}</div>
                </div>

                <div className='propertyhorizontalcard-statusContainer'>
                    <div className='propertyhorizontalcard-statusIcon'/>
                    <div className='propertyhorizontalcard-statusText'>Se {property?.listingType === 'rent'? 'Renta' : 'Vende'} {homeTypeLabel(property?.homeType)}</div>
                </div>

                <div className='propertyhorizontalcard-descriptionText'>
                    {  
                        property?.description? 
                            property.description.length > 75 ?
                                property.description.substring(0, 75) + '...'
                            :
                                property.description
                        : '...'
                    }
                </div>
            </div>

            <div className='propertyhorizontalcard-priceText'>
                ${property?.price? property.price : ''}
            </div>
        </div>
    );
}

export default PropertyHorizontalCard;
