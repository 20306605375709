import React, {useState, useEffect} from 'react';
import './HomeTypeFilterModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import FilterOption from "../FilterOption/FilterOption";

Modal.setAppElement(document.getElementById('root'));

function HomeTypeFilterModal({isOpen, onRequestClose}) {
    const dispatch = useDispatch();
    const homeTypeFilter = useSelector(state => state.property.homeTypeFilter);

    const listingTypeFilter = useSelector(state => state.property.listingTypeFilter);
    const priceMinFilterRent = useSelector(state => state.property.priceMinFilterRent);
    const priceMaxFilterRent = useSelector(state => state.property.priceMaxFilterRent);
    const priceMinFilterSale = useSelector(state => state.property.priceMinFilterSale);
    const priceMaxFilterSale = useSelector(state => state.property.priceMaxFilterSale);

    useEffect(function () {
    }, [homeTypeFilter]);

    function changeFilter(homeType) {
        let currentFilter = Object.assign([], homeTypeFilter);

        if(!Array.isArray(currentFilter)) currentFilter = [currentFilter];

        if (currentFilter.length === 0 ){
            homeTypeFilter.push('home');
            homeTypeFilter.push('apartment');
            //homeTypeFilter.push('terracedhouse');
            homeTypeFilter.push('land');
            //homeTypeFilter.push('condominium');
            //homeTypeFilter.push('prefabricated');
            homeTypeFilter.push('farm');
            homeTypeFilter.push('commercial');
            homeTypeFilter.push('other');
        }

        const typeIdx = currentFilter.indexOf(homeType);

        if (typeIdx > -1) {
            currentFilter.splice(typeIdx, 1);
        } else {
            currentFilter.push(homeType);
        }

        dispatch(actions.property.changeFilters(listingTypeFilter, priceMinFilterRent, priceMaxFilterRent,priceMinFilterSale, priceMaxFilterSale, currentFilter));
    }

    function filtersContain(filter) {
        return homeTypeFilter?.indexOf(filter) > -1;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Listing Type Filter'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='hometypefiltermodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <div className='hometypefiltermodal-content'>
                <button onClick={onRequestClose} className='hometypefiltermodal-close-btn'>
                    <GrClose/>
                </button>

                <div className='hometypefiltermodal-options'>
                    <FilterOption label={'Casas'} selected={filtersContain("home") || homeTypeFilter.length === 0} onPress={() => changeFilter("home")}/>
                    <FilterOption label={'Apartamentos'} selected={filtersContain("apartment") || homeTypeFilter.length === 0} onPress={() => changeFilter("apartment")}/>
                    {/*<FilterOption label={'Casas Adosadas'} selected={filtersContain("terracedhouse") || homeTypeFilter.length === 0} onPress={() => changeFilter("terracedhouse")}/>*/}
                    <FilterOption label={'Lotes / Terrenos'} selected={filtersContain("land") || homeTypeFilter.length === 0} onPress={() => changeFilter("land")}/>
                    {/*<FilterOption label={'Condominio'} selected={filtersContain("condominium") || homeTypeFilter.length === 0} onPress={() => changeFilter("condominium")}/>*/}
                    {/*<FilterOption label={'Fabricado'} selected={filtersContain("prefabricated") || homeTypeFilter.length === 0} onPress={() => changeFilter("prefabricated")}/>*/}
                    <FilterOption label={'Fincas'} selected={filtersContain("farm") || homeTypeFilter.length === 0} onPress={() => changeFilter("farm")}/>
                    <FilterOption label={'Local Comercial'} selected={filtersContain("commercial") || homeTypeFilter.length === 0} onPress={() => changeFilter("commercial")}/>
                    <FilterOption label={'Otro'} selected={filtersContain("other") || homeTypeFilter.length === 0} onPress={() => changeFilter("other")}/>
                </div>

                <PrimaryButton label={'Aplicar'} onClick={onRequestClose}/>
            </div>
        </Modal>
    );
}

export default HomeTypeFilterModal;
