import React, {useEffect, useState} from 'react';
import './ContactList.css';
import ChatPreview from "../../components/ChatPreview/ChatPreview";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/modules";
import Input from "../../components/Input/Input";
import { FadeLoader } from 'react-spinners';


function ContactList() {
    const chats = useSelector(state => state.chat.chats);
    const loading = useSelector(state => state.chat.loadingChats);
    const [contacts, setContacts] = useState([]);
    const [query, setQuery] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.chat.loadChats());
    }, []);

    useEffect(() => {
        setContacts(chats);
    }, [chats]);

    return (
        <div className='contactlist-container'>
            <div className='contactlist-header'>
                <Input className='contactlist-input' placeholder={'Buscar en contactos'} value={query} onChange={query => setQuery(query)}/>
            </div>

            <div className='contactlist-scrollView'>
                <FadeLoader
                    color={'#88D203'}
                    loading={loading}
                />
                {renderList()}
            </div>
        </div>
    );

    function renderList() {            
        if (!contacts.filter(c => c.lastMessage) || contacts.filter(c => c.lastMessage).length === 0) {
            return <div className='contactlist-emptyStateText'>Sin contactos añadidos.</div>
        }
        return contacts
            .filter(c => c.lastMessage)
            .sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp)
            .map(c =>
                <ChatPreview
                    userId={c.otherUserId}
                    chatId={c._id}
                    key={c._id}
                    lastMessage={c.lastMessage}
                    unseenCount={c.unseenCount}
                    query={query}
                />
            );
    }
}

export default ContactList;
