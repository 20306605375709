import React, {useState} from 'react';
import './PropertyModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import IconButton from "../IconButton/IconButton";
import { GrMap } from 'react-icons/gr';
import { ImImage } from 'react-icons/im';
import {FaPhoneAlt} from "react-icons/all";
import Map, {markerTypes} from "../Map/Map";
import SendMessage from "../SendMessage/SendMessage";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SimpleMap from "../Map/ReactMap";
import { useDispatch } from 'react-redux';
import {actions} from "../../store/modules";
import {useHistory} from "react-router-dom";
import homeTypeLabel from '../../utils/homeTypeLabel';


Modal.setAppElement(document.getElementById('root'));

function PropertyModal({isOpen, onRequestClose, property}) {
    const dispatch = useDispatch();
    const history = useHistory();

    let imageUrl = property?.pictures && property?.pictures.length > 0 ? property.pictures[0] : 'homepage_header.png';

    const [tab, setTab] = useState(1);

    //message
    const [messageMode, setMessageMode] = useState(false);

    function changeTab(tab) {
        setTab(tab);
        setMessageMode(false);
    }

    function close() {
        setMessageMode(false);
        onRequestClose()
    }

    function images() {
        let pics = [];

        if (property && property.pictures) {
            pics = property?.pictures?.map((p,index) => (
                <div className="each-slide" key={index}>
                    <div style={{'backgroundImage': `url(${p})`}}>
                    </div>
                </div>
            ));
        }
        return pics;
    }

    function openChat() {
        dispatch(actions.chat.createChat(property.owner, history));
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            contentLabel='Property Preview'
            style={{overlay: {backgroundColor: '#101C2A88', position: 'absolute', zIndex: 1003}}}
            className='propertymodal-container'
            shouldCloseOnOverlayClick={true}
        >
            <button onClick={close} className='propertymodal-close-btn'>
                <GrClose/>
            </button>

            <div className='propertymodal-slider'>
                {
                    !messageMode?
                        (
                            tab === 1?
                                <Slide easing="linear" autoplay={false} transitionDuration={100} indicators>
                                    {images()}
                                </Slide>
                                :
                                tab === 0 &&
                                <div style={{height: '75vh'}}>
                                    <SimpleMap
                                        center={{ lat: property?.latitude, lng: property?.longitude }}
                                        markers={[{latitude: property?.latitude, longitude: property?.longitude, price: property?.price}]}
                                        markerType={markerTypes.PRICE_MARKER}
                                        onMarkerClick={()=>{}}
                                    />
                                </div>
                        )
                        :
                        <img src={'send_message.png'} className='propertymodal-slider' alt={'send message'}/>
                }
                <div className='propertymodal-bottomtabs'>
                    <IconButton label={'Vista de Calle'} icon={<GrMap className='iconbuttoon-icon'/>}  selected={tab===0} onClick={() => changeTab(0)}/>
                    <IconButton label={'Fotos'} icon={<ImImage className='iconbuttoon-icon'/>} selected={tab===1} onClick={() => changeTab(1)}/>
                </div>
            </div>

            <div className='propertymodal-content'>
                {
                    !messageMode?
                    <>
                        <div className='propertymodal-title'>
                            {property?.city}
                        </div>
                        <div className='propertymodal-subtitle'>
                            {property?.address}
                        </div>
                        <div className='propertymodal-subtitle2'>
                            {homeTypeLabel(property?.homeType)}
                        </div>
                        {
                            property?.homeType === 'land' ?
                                <div className='propertymodal-subtitle2'>
                                    {property?.area} m2
                                </div>
                                :
                                <div className='propertymodal-subtitle2'>
                                    {property?.beds} habitaciones • {property?.baths} baños • {property?.area} m2
                                </div>
                        }

                        <div className='propertymodal-listingtype-container'>
                            <div className='propertymodal-listingtype-icon'/>
                            <div className='propertymodal-listingtype-label'>
                                {property?.listingType === 'sale' ? 'En venta' : 'Alquilar'}
                            </div>
                        </div>

                        <div className='propertymodal-price'>
                            ${property?.price}
                        </div>

                        {/*<div className='propertymodal-estimate'>
                            Zestimate: $325,500
                        </div>
                        <div className='propertymodal-estimate'>
                            Est. Mortagage: $1,002/mo
                        </div>*/}

                        <div className='propertymodal-description'>
                            {property?.description}
                        </div>

                        <PrimaryButton label={'Contactar Agente'} onClick={openChat}/>
                        {
                            !!property?.phone &&
                            <div className='propertymodal-phone'>
                                <FaPhoneAlt/>
                                {' '}
                                {property?.phone || 'No disponible'}
                            </div>
                        }
                    </>
                        :
                        <SendMessage/>
                }
            </div>
        </Modal>
    );
}

export default PropertyModal;
