import React from 'react';
import PropTypes from 'prop-types';
import './PriceMarker.css';

const propTypes = {
    amount: PropTypes.number.isRequired,
    fontSize: PropTypes.number,
};

class PriceMarker extends React.Component {
    formatThousands(amount) {
        return amount >= 1000000 ? (amount/1000000).toFixed(0) + " M" : (amount >= 1000 ? (amount/1000).toFixed(0) + " k" : amount)
    }

    render() {
        const { amount, onClick, property, listingType } = this.props;
        return (
            <div 
                className={[
                    'pricemarker-container',
                    listingType === 'sale'? 'pricemarker-container-sale' : 'pricemarker-container-rent'
                ].join(' ')}
                onClick={() => onClick(property)}
            >
                {this.formatThousands(amount)}
            </div>
        );
    }
}

PriceMarker.propTypes = propTypes;

export default PriceMarker;
