import {ratingActionTypes} from './constants'
import {ratingService} from "../../services/ratingService";

export const getAgentRatings = (agentId) => {
    return function(dispatch) {

        dispatch({type: ratingActionTypes.FETCH_RATINGS_REQUEST});

        return ratingService.getAgentRatings(agentId)
            .then(data => {
                    dispatch({
                        type: ratingActionTypes.FETCH_RATINGS_SUCCESS,
                        payload: {
                            ratings: data
                        }
                    });
                },
                error => {
                    dispatch({
                        type: ratingActionTypes.FETCH_RATINGS_FAILURE,
                    });
                    return [];
                });
    };
};

export const fetchAgentAverage = (agentId) => {
    return function(dispatch) {

        dispatch({type: ratingActionTypes.FETCH_RATINGS_AVERAGE_REQUEST});

        return ratingService.fetchAgentAverage(agentId)
            .then(data => {
                    dispatch({
                        type: ratingActionTypes.FETCH_RATINGS_AVERAGE_SUCCESS,
                        payload: {
                            average: data
                        }
                    });
                },
                error => {
                    dispatch({
                        type: ratingActionTypes.FETCH_RATINGS_AVERAGE_FAILURE,
                    });
                    return 0;
                });
    };
};

export const createRating = (comment, rating, agentId) => {
    return function(dispatch) {

        dispatch({type: ratingActionTypes.CREATE_RATING_REQUEST});

        return ratingService.createRating(comment, rating, agentId)
            .then(data => {
                    dispatch({
                        type: ratingActionTypes.CREATE_RATING_SUCCESS,
                        payload: {
                            createdRating: data
                        }
                    });
                },
                error => {
                    dispatch({
                        type: ratingActionTypes.CREATE_RATING_FAILURE,
                    });
                });
    };
};

export const fetchOwnRating = (agentId) => {
    return function (dispatch) {
        dispatch({type: ratingActionTypes.FETCH_OWN_RATING_REQUEST});

        return ratingService.fetchOwnRating(agentId)
            .then(data => {
                    dispatch({
                        type: ratingActionTypes.FETCH_OWN_RATING_SUCCESS,
                        payload: {
                            rating: data
                        }
                    });
                },
                error => {
                    dispatch({
                        type: ratingActionTypes.FETCH_OWN_RATING_FAILURE,
                    });
                });
    };
};

export const resetRatingScreen = () => {
    return function (dispatch) {
        return dispatch({type: ratingActionTypes.RESET_RATINGS_SCREEN});
    };
};

